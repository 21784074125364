<template>
    <div class="start_animation_div">
        <button class="start_animation_btn" @click="handleAnimation" v-if="introVideo && productComponentLoaded == false && isDrRoom == true&& menuClick==false&& !imageMachine">
            {{ animationPlay ? 'Stop Animation': "Start Animation" }}
        </button>
    </div>
    <div class="main-aria product_aria "  v-if="introVideo && productComponentLoaded == false && isDrRoom == true&& menuClick==false">
        <div class="container-fluid" v-if="introVideo &&productComponentLoaded == false && isDrRoom == true ">
            <div class="row justify-content-end">

                <div class="col-sm-12 mult_suite_inner item aos-init aos-animate"  data-aos="fade-up">
                    <div class="float-right">
                        <ul id="navigationMenu">
                            <li v-for="(machine_menu,index) in machine_menus"
                            v-bind:key="machine_menu.id" :class="{ 'active': activeIndex === index }"
                            >
                                <a class="about" href="#" @click="loadMachine(machine_menu,index);"  >
                                    <div class="side_menu_contant">
                                        <h5>{{ machine_menu.title }}</h5>
                                        <p>{{ machine_menu.sub_title }}</p>
                                    </div>
                                    <span>{{ machine_menu.hover }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dr_800" v-if="machine_name!=null&&isDrRoom == true">
        <p v-if="one_hundred_e"><small>DR 100e is not currently available in the US and Canada</small></p>
        {{ machine_name }}
        <p>Click & drag to explore</p>
    </div>

</template>

<script>
import axios from "axios";
export default {
  name: "DrRoom",
  data() {
    return {
      modalFlag: false,
      modalFlagImg: true,
      imageMachine: false,
      one_hundred_e: false,
      modal_class: "",
      activeIndex: undefined,
      email: "",
      reg: "",
      machine_name: "",
      machine_menus: [],
    };
  },
  components: {
  },
  mounted() {
  },
  computed: {
    sceneLoaded() {
      return this.$store.state.sceneLoaded;
    },
    DRId() {
      return this.$store.state.annotationActiveId;
    },
    isDrRoom() {
      return this.$store.state.isDrRoom;
    },
    productComponentLoaded() {
      return this.$store.state.productContentLoaded;
    },
    introVideo() {
      return !this.$store.state.introVideoStarted;
    },
    animationPlay() {
      return this.$store.state.animationPlay;
    },
    menuClick() {
      return this.$store.state.menuClick;
    },
    machineFirstLoad() {
        return this.$store.state.activeModel;
    },

  },
  watch: {
    sceneLoaded(val){
        if(val){
          this.modalFlagImg = false;
          var vm = this;
          setTimeout(function() {
            vm.modal_class = "";
          }, 1500);
        }
    },
    introVideo(val){
        if(val){
          this.loadMenus();
        }
    },
  },
  methods: {
    loadMenus() {
        var product_id = this.machineFirstLoad.id;
        this.$store.dispatch("setLoadingSpinner", true);
        axios
        .post(
          window.baseUrl+"/api/machine/menus/get",
          {
            user_id: this.$store.state.auth_user.id,
            product_id: product_id,
            type: 2
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
            if (response.data.response == "success" && response.data.menu_count !=0) {
                this.machine_menus = response.data.menus;
                this.machine_name = this.machine_menus[0].sub_title+' '+this.machine_menus[0].title;
                this.setActive(this.machine_menus[0], 0);
            }
        });

    },

    closePopup(){
        this.modal_class = "";
    },
    loadMachine(machine_menu, index) {
      console.log(machine_menu.load_machine)
      this.setActive(machine_menu, index);
      this.machine_name = machine_menu.sub_title+' '+machine_menu.title;
      this.$store.dispatch("setProductContentFlagLoaded", true);
      if(machine_menu.load_machine == 'DX-D-100'){
        this.$parent.loadImageModel(machine_menu.load_machine);
      }else{
        this.$parent.loadMachine(machine_menu.load_machine);
      }
    },
    handleAnimation(){
      this.$parent.handleAnimation()
    },
    setActive(machine_menu, index) {
          this.activeIndex = index;
    },
    setimageMachine(val) {
          this.imageMachine = val;
    },
    setInfo(val) {
          this.one_hundred_e = val;
    },

  },
};
</script>
<style lang="css">
/* .item {
  position: absolute !important;
} */
</style>
