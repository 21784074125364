<template>
      <div class="skip_btn" @click="stopVideo()" v-if="modalFlag">SKIP</div>
      <div class="transparent-aria" v-if="modalFlag">
        <video controls="false" autoplay="autoplay" playsinline="" @ended="onEnd()" poster="data:image/gif,AAAA" ref="video" @timeupdate="onTimeUpdateListener" :muted="isVideoMuted">
          <source :src="videoName1" type="video/webm">
          <source :src="videoName2" type="video/quicktime">
        </video>
      </div>
</template>

<script>
import axios from "axios";
export default {
  name: "IntroProductVideo",
  data() {
    return {
      modalFlag: false,
      videoName: "",
      videoName1: "",
      videoName2: "",
    };
  },
  mounted() {},
  computed: {
    isVideoMuted() {
      return this.$store.state.muteVideo;
    },
    menuClick() {
      return this.$store.state.menuClick;
    },
  },
  watch: {
    isVideoMuted: function(crrState, preState) {
        var vid = this.$refs.video;
        if(vid!=null){
            if (crrState) {
                vid.muted = true;
            }else{
                vid.muted = false;
            }
        }

    },
  },
  methods: {
    showVideo: function(val) {
        this.$store.dispatch("setIntroVideoStarted", true);
        this.videoName = val;
        this.videoName1 = "/videos/"+val+".webm";
        this.videoName2 = "/videos/"+val+".mp4";
        this.modalFlag = true;
    },
    stopVideo: function() {
        this.$store.dispatch("setIntroVideoStarted", false);
        this.$parent.$refs['Menu'].menuClickedVideo();
        this.videoName1 = "";
        this.videoName2 = "";
        this.modalFlag = false;

        if(this.videoName != 'Welcome' && this.videoName != '' && this.menuClick==false){
            this.$parent.$refs['ProductContent'].loadOverview();
        }
        this.videoName = "";
    },
    muteVideo: function() {
        var vid = this.$refs.video
        vid.muted = !vid.muted
    },

    onEnd(){
        this.$store.dispatch("setIntroVideoStarted", false);
        if(this.videoName == 'Welcome'){
            this.$parent.$refs['Menu'].menuSelected('products');
        }
        if(this.videoName != 'Welcome'){
            this.$parent.$refs['ProductContent'].loadOverview();
        }

        this.modalFlag = false;
    },
    onTimeUpdateListener(){
        if(this.videoName == 'Welcome'){
            var vid = this.$refs.video;
            if(vid!=null){
                var currentTime = parseInt(vid.currentTime);
                if(currentTime == 46){
                    this.$parent.$refs['Menu'].menuClickedVideo();
                }
            }


        }


    },
  },
};
</script>

<style>
    video {
      pointer-events: none;
    }
</style>
