import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from './router'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
window.$ = window.jQuery = require("jquery");
let config = require('../config.js');
let app_env = config['env'];
window.baseUrl = config[app_env].url;
window.host_url = config['host_url'].url;
window.app_env = app_env;
createApp(App)
  .use(router)
  .use(store)
  .mount("#app");
