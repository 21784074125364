<template>
    <div class="main-aria" v-if="modalFlag">
      <div class="container-fluid">
        <div class="row justify-content-center " >
          <div
            class="col-md-11 col-lg-10 col-xl-7 item"
            :class="modal_class"
            data-aos="fade-up"
          >
            <div class="login_box p-5">
              <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup()"><i class="far fa-times-circle"></i></a></div>
              <h3 class="col_red text-center">Events</h3>
              <div class="event_head_box mt-5">
                <div class="row">
                  <div class="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-3">
                    Day / Time (CST)
                  </div>
                  <div class="col-6 col-sm-7 col-md-8 col-lg-8 col-xl-9 text-center">
                    Name of Event
                  </div>
                </div>
              </div>
              <div class="scrollbar scrollbar-black bordered-black square thin">
                <div class="force-overflow">
                  <div class="event_head_box event_head_box2 mt-4">
                    <div
                      class="card example-1 scrollbar-deep-purple bordered-deep-purple thin"
                    >
                      <div class="card-body">
                        <div
                          class="event_head_inner"
                          v-for="eventOb in events"
                          v-bind:key="eventOb.id"
                        >
                          <div class="row" @click="loadEvent(eventOb.url,eventOb.id);" :class="eventOb.url!=null?'event_hover':''">
                            <div class="col-3 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                              {{ eventOb.date_time }}
                            </div>
                            <div class="col-7 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                              <a href="#" :title="eventOb.url!=null?'Click to View':''">{{ eventOb.event_name }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="https://caremeansshare.com/survey/" class="survey_btn" @click="loadEvent('https://caremeansshare.com/survey/',0);" > Take Our Survey</a>
              <p>Please take our survey and Agfa will make a donation to Doctors Without Borders</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div>
	</section> -->
</template>

<script>
import axios from "axios";
export default {
  name: "Events",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      events: []
    };
  },
  components: {

  },
  mounted() {},
  computed: {
    productDetailsFlag(){
      return this.$store.state.productContentFlagLoaded;
    }
  },
  methods: {
    // login
    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        this.modalFlag = val;
        this.$store.dispatch("setMenuFlagLoaded", false);
        return;
      }
      this.$store.dispatch("setLoadingSpinner", true);
      this.postAnalytics(10000,0);
      axios
        .post(
          window.baseUrl+"/api/events/get",
          {
            user_id: this.$store.state.auth_user.id
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.events = response.data.events;
            this.modalFlag = val;
            this.$store.dispatch("setMenuFlagLoaded", true);

            var vm = this;
            setTimeout(function() {
              vm.modal_class = "aos-init aos-animate";
            }, 500);
          }
        });
    },
    closePopup(){
        this.postAnalytics(10000,1);
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setMenuFlagLoaded", false);
        this.$store.dispatch("setMenuClick", false);
    },
    loadEvent(url,event_id){
        if(event_id!=0){
            this.postAnalytics(event_id,0);
        }
        if(url!=null){
            window.open(url, '_blank');
        }
        return true;
    },
    postAnalytics(event_id,stop){
        axios
        .post(
          window.baseUrl+"/api/analytics/post",
          {
            user_id: this.$store.state.auth_user.id,
            product_id: event_id,
            page: 'event',
            feature_id: 0,
            stop: stop
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {

        });
    },
  },
  watch:{
    productDetailsFlag(val){
      if(val == true){
        this.closePopup()
      }
    }
  }
};
</script>


