<template>

      <div class="main-aria" v-if="modalFlag">
        <div class="container-fluid">
          <div class="row justify-content-center " >
            <div
              class="col-md-11 col-lg-9 col-xl-6 item  "
              :class="modal_class"
              data-aos="fade-up"
            >
              <div class="login_box p-5 text-center">

                <div class="row justify-content-center">
                  <div class="col-md-12 col-lg-10 col-xl-9">
                    <h3 class="mb-4">Welcome to the Agfa Virtual Experience</h3>
                    <img
                      src="/images/care_compare_logo.svg"
                      class="img-fluid w-100"
                      alt=""
                    />
                    <form
                      class="email_enter_box"
                      v-on:submit.prevent="continueWithEmail()"
                    >
                      <div class="form-group my-5">
                        <input
                          autofocus
                          type="email"
                          class="form-control w-100"
                          placeholder="Please enter your email to begin "
                          v-model="email"
                        />
                      </div>
                      <div class="form-group">
                        <button
                          type="button"
                          class="btn login_btn continue_btn"
                          @click="continueWithEmail()"
                          :disabled="isEmailValid()"
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="bg_div" v-if="modalFlagImg" style="background: #000;"><img src="/images/atrium-banner-1.jpg" alt="" class="w-100 bg_img" /></div>
</template>

<script>
import validator from "validator";
import axios from "axios";
export default {
  name: "WelcomeEmail",
  data() {
    return {
      modalFlag: true,
      modalFlagImg: true,
      modal_class: "",
      email: "",
      reg: "",
    };
  },
  components: {
  },
  mounted() {
    //  TEMP
    // this.$store.dispatch("setCurrentState", "three");
    // this.$store.dispatch("setMenuFlag", true);
    // this.modalFlag = false;
    // this.modal_class = "";
    if(window.app_env=='localhost'){
        this.email = 'test@mail.com';
    }
  },
  computed: {
    sceneLoaded() {
      return this.$store.state.sceneLoaded;
    },
  },
  watch: {
    sceneLoaded(val){
        if(val){
          this.modalFlagImg = false;
          var vm = this;
          setTimeout(function() {
            vm.modal_class = "";
          }, 1500);


          this.$parent.$refs['introProductVideo'].showVideo('Welcome');
        }
    }
  },
  methods: {
    showPopup(val) {
      if (!val) {
        this.modal_class = "";
        return;
      }
      this.modalFlag = val;
      this.modalFlagImg = val;
      var vm = this;
      setTimeout(function() {
        vm.modal_class = "aos-init aos-animate";
      }, 1000);
    },

    closePopup(){
        this.modal_class = "";
        // this.modalFlag = false;
    },
    //email validation
    isEmailValid: function() {
      return this.email == ""
        ? true
        : validator.isEmail(this.email)
        ? false
        : true;
    },

    // login
    continueWithEmail: function() {
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .post(window.baseUrl+"/api/user/login", {
          email: this.email,
        })
        .then((response) => {
            this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.modalFlag = false;
             this.$store.dispatch("setCurrentState", "three");
            this.$store.dispatch("setMenuFlag", true);
            this.$store.dispatch("setAuthUser", response.data.data);

          }
        });
    },
  },
};
</script>
<style lang="css">
/* .item {
  position: absolute !important;
} */
</style>
