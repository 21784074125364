<template>
  <Menu ref="Menu" />
  <div>
    <EnterEmail ref="enterEmail" />
    <Products ref="products" />
    <!-- Disabled Events
      <Events ref="eventList" />
     -->
    <!-- Disabled Schedule a Demo
      <ScheduleStepOne ref="ScheduleStepOne" /> 
    -->
    <ScheduleDisabled ref="ScheduleStepOne" /> <!-- Disabled Schedule a Demo Modal -->
    <OnDemandContent ref="onDemand" />
    <ProductContent ref="ProductContent" />
    <DRComponent ref="DRComponent" />
    <MobileDRComponent ref="MobileDRComponent" />
    <IntroProductVideo ref="introProductVideo" />
  </div>

  <div id="threejs-container"></div>

  <div class="basic-loading-spinner" v-if="loadingSpinner === true">
    <!-- <div>{{ loadingPercentage }}</div> -->
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <!-- <div
    id="patient-room-buttons"
    v-if="isCubeMap === true"
    style="position: absolute; left: 0px;top: 10%"
  >
    <div class="patient-room-button" @click="loadRoom('DR Room')">
      DR ROOM
    </div>
    <div class="patient-room-button" @click="loadRoom('Gallery Room')">
      Gallery Room
    </div>
  </div> -->

  <!-- <div
    id="patient-room-buttons"
    v-if="isDrRoom === true"
    style="position: absolute; right: 0px;top: 150px"
  >
    <div class="patient-room-button" @click="loadMachine('DR-100-E')">
      DR 100 E
    </div>
    <div class="patient-room-button" @click="loadMachine('DR-100-S')">
      DR 100 S
    </div>
  </div>

  <div
    id="patient-room-buttons"
    v-if="isGallery === true"
    style="position: absolute; right: 0px;top: 150px"
  >
    <div class="patient-room-button" @click="loadMachine('DX-D-300')">
      DX D 300
    </div>
    <div class="patient-room-button" @click="loadMachine('DR-400')">
      DR 400
    </div>
    <div class="patient-room-button" @click="loadMachine('DR-600')">
      DR 600
    </div>
    <div class="patient-room-button" @click="loadMachine('DR-800')">
      DR 800
    </div>
  </div> -->

  <AgfaMainLoader ref="showMainAgfaLoader" />
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import AgfaMainLoader from "../components/MainLoaderComponent.vue";
import EnterEmail from "../components/WelcomeEmailComponent.vue";
import Events from "../components/EventsComponent.vue";
import OnDemandContent from "../components/OnDemandContentComponent.vue";
import Products from "../components/ProductComponent.vue";
import ProductContent from "../components/ProductContentComponent.vue";
import Menu from "../components/MenuComponent.vue";
// import HomePage from "../components/HomePage.vue";
import ScheduleStepOne from "../components/schedule/ChooseProductComponent.vue";
import ScheduleDisabled from "../components/schedule/ScheduleDisabled.vue";
import IntroProductVideo from "../components/IntroProductVideoComponent.vue";
import DRComponent from "../components/DRComponent.vue";
import MobileDRComponent from "../components/MobileDRComponent.vue";
import ThreeScene from "../threejs/scene";

var threeScene;

export default {
  name: "Home",
  components: {
    AgfaMainLoader,
    EnterEmail,
    Menu,
    Events,
    Products,
    ScheduleStepOne,
    ScheduleDisabled,
    OnDemandContent,
    ProductContent,
    IntroProductVideo,
    DRComponent,
    MobileDRComponent,
  },
  mounted() {
    console.log("from mounted", this.$store.state.currentState);
    // this.$store.dispatch("setCurrentState", "three");
    // this.initThree();
    // this.loadInitalCube();
    this.$store.dispatch("setLoadingSpinner", true);
    var vueThis = this;
    window.addEventListener("load", function(event) {
      vueThis.$store.dispatch("setLoadingSpinner", false);
    });
  },

  setup() {
    const store = useStore();
    const currentState = computed(() => store.state.currentState);
    const sceneLoaded = computed(() => store.state.sceneLoaded);
    const isCubeMap = computed(() => store.state.isCubeMap);
    const isDrRoom = computed(() => store.state.isDrRoom);
    const isGallery = computed(() => store.state.isGallery);
    const animationPlay = computed(() => store.state.animationPlay);

    const changeSceneLoaded = () => store.dispatch("setSceneLoaded", true);

    const changeCurrentRoom = (data) => store.dispatch("setCurrentRoom", data);

    return {
      currentState,
      sceneLoaded,
      changeSceneLoaded,
      changeCurrentRoom,
      isCubeMap,
      isDrRoom,
      isGallery,
      animationPlay,
    };
  },

  methods: {
    initThree() {
      threeScene = new ThreeScene();
    },
    loadInitalCube() {
      threeScene.loadCubeMapInit();
      this.changeCurrentRoom({
        isCubeMap: true,
        isGallery: false,
        isDrRoom: false,
      });
    },
    loadCubeMapFromHome() {
      threeScene.loadCubeMapFromRoom();
    },
    loadRoom(name) {
      this.$store.dispatch("setLoadingSpinner", true);
      threeScene.loadRoom(name);
    },
    loadMachine(machine) {
      // threeScene.loadMachine();
      // this.$refs.showAgfaLoader.showLoader();
      this.$store.dispatch("setLoadingSpinner", true);

      threeScene.handleProductLoading({
        name: machine,
        isDrRoom: this.isDrRoom,
        isGallery: this.isGallery,
      });
      // this.$refs.showAgfaLoader.hideLoader();
    },
    loadImageModel(machine) {
      this.$store.dispatch("setLoadingSpinner", true);

      threeScene.handleImageProductLoading({
        name: machine,
        isDrRoom: this.isDrRoom,
        isGallery: this.isGallery,
      });
    },
    handleAnimation() {
      this.animationPlay
        ? threeScene.stopAnimation()
        : threeScene.activateAnimation();
    },
    clickedCont() {
      if (this.sceneLoaded == true) {
        console.log(this.$refs);
      }
      this.$refs.enterEmail.showPopup(true);
    },
  },
  computed: {
    StorecurrentState() {
      return this.$store.state.currentState;
    },
    StoresceneLoaded() {
      return this.$store.state.sceneLoaded;
    },

    loadingSpinner() {
      return this.$store.state.loadingSpinner;
    },
    loadingPercentage() {
      return this.$store.state.loadingPercentage;
    },
    activeAtriumChange() {
      return this.$store.state.activeAtriumBannerId;
    },
  },

  watch: {
    currentState: function(crrState, preState) {
      if (crrState === "three" && preState === "home") {
        this.$store.dispatch("setLoadingSpinner", true);
        this.initThree();
        this.loadInitalCube();
      }
    },
    StoresceneLoaded(crrState) {
      if (crrState) {
        //
      }
    },
    activeAtriumChange(crrState) {
      if (crrState == 1) {
        this.$refs.Menu.menuSelected("products");
      } else if (crrState == 2) {
        this.$refs.Menu.menuSelected("ScheduleStepOne");
      } else if (crrState == 3) {
        this.$refs.Menu.menuSelected("eventList");
      }
      this.$store.dispatch("setAtriumBannerClick", 0);
    },
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Agfa Virtual Experience";
      },
    },
  },
};
</script>

<style>
.basic-loading-spinner {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  top: 50%;
  transform: translateY(-50%);
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid red;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: red transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
