<template>
        <div class="main-aria product_content"  v-if="modalFlag">
            <div class="container-fluid">
                <div class="row justify-content-end">
                    <div class="col-sm-12 mult_suite_inner item" :class="modal_class" data-aos="fade-up">


                        <div class="login_box p-3 text-center">
                            <div class="col-md-12">
                                <div class="close_btn m-2" style="position: absolute;top: 0;right: 9px;"><a href="" @click="closePopup()"><img src="images/close_btn.svg" alt="" class="img-fluid" /></a></div>
                            </div>
                            <div class="red_feature_div">
                                <iframe :src='videoId' :width='YWIDTH' :height='YHEIGHT' frameborder='0' allow='autoplay; fullscreen' allowfullscreen v-if="vimeo_show">

                                </iframe>
                                <iframe :width='YWIDTH' :height='YHEIGHT' :src="videoId" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="youtube_show"></iframe>
                                <img
                                  :src="img_src"
                                  alt=""
                                  class="w-100"
                                  v-if="image_show"
                                  :player-parameters="playerParams"
                                />
                                <h3 class="col_red mt-3">{{ title_content }}</h3>
                                <h5>Features</h5>
                                <div class="card example-1 scrollbar-deep-purple bordered-deep-purple thin">
                                    <div class="card-body red_feature_inner text-left" v-html="main_content">
                                    </div>
                                </div>
                                <div class="dow_pdf_btns">
                                    <a href="" class="schedule_btn" @click="scheduleDemo()">Schedule a Demo</a>
                                    <a href="#" class="schedule_btn" @click="downloadPdf()" :download="file_name" >View/Download PDF<i class="fas fa-download"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ProductContent",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      musica_play: false,
      musica: "#",
      demand_contents: [],
      image_show: false,
      vimeo_show: false,
      youtube_show: false,
      videoId : false,
      YWIDTH : '100%',
      YHEIGHT : '500px',
      img_src: "images/demand_content_img1.jpg",
      title_content: "",
      main_content: "",
      pdf_url: "",
      file_name: "",
      short_url: "",
      long_url: "",
      current_index: "",
    };
  },
  components: {
  },
  mounted() {},
  computed: {
    hotspotClick() {
        return this.$store.state.annotationActiveId;
    },
    machineFirstLoad() {
        return this.$store.state.activeModel;
    },
    machineLoad() {
        return this.$store.state.infoData;
    },
    menuFlagLoaded() {
        return this.$store.state.menuFlagLoaded;
    },
    isGallery() {
      return this.$store.state.isGallery;
    },
    isDrRoom() {
      return this.$store.state.isDrRoom;
    },
    introVideo() {
      return !this.$store.state.introVideoStarted;
    },
  },
  watch: {
    hotspotClick(val){

        if(val!= false){
            this.closePopup();
            var productName = val.split("-");
            this.showProductContent(productName);
            this.$store.dispatch("setAnnotationActiveId", false);
        }else{
            // this.modal_class = "";
            // this.modalFlag = false;
            // this.$store.dispatch("setModalShow", this.modalFlag);
        }

    },
    machineLoad(val){
        if (typeof val.id !== 'undefined') {
            this.closePopup();
            if(val.length!=0){
                var productName = [];
                productName.push(val.id);
                this.showProductContent(productName);
                this.$store.dispatch("setInfoData", []);
            }else{
                this.modal_class = "";
                this.modalFlag = false;
                this.$store.dispatch("setModalShow", this.modalFlag);
            }
        }
    },
    machineFirstLoad(val){
        if (typeof val.id !== 'undefined') {
            //this.postAnalytics(val.id,0);
            if(val.id==5){
                this.$parent.$refs['DRComponent'].setimageMachine(true);
            }else{
                if(val.id==4){
                    this.$parent.$refs['DRComponent'].setInfo(true);
                }else{
                    this.$parent.$refs['DRComponent'].setInfo(false);
                }
                this.$parent.$refs['DRComponent'].setimageMachine(false);
            }
            if(this.introVideo){
                this.loadOverview();
            }
        }
    },
    menuFlagLoaded(val){
        if(val){
          this.closePopup();
        }
    },
    isGallery(val){
        if(val){
          this.$parent.$refs['introProductVideo'].showVideo('DR');
        }
    },
    isDrRoom(val){
        if(val){
          this.$parent.$refs['introProductVideo'].showVideo('Mobile');
        }
    },
  },
  methods: {
    // login
    showProductContent: function(productName) {
        this.$store.dispatch("setProductContentLoaded", true);
        this.$store.dispatch("setProductContentFlagLoaded", true);
        this.$store.dispatch("setLoadingSpinner", true);

        if(!this.introVideo){
            this.$parent.$refs['introProductVideo'].stopVideo();
        }

        var product_id = productName[0];
        var feature_id = 0;
        if(productName.length == 2){
            feature_id = productName[1];
        }
        axios
        .post(
          window.baseUrl+"/api/products/get",
          {
            user_id: this.$store.state.auth_user.id,
            id: product_id,
            feature: feature_id
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
            if (response.data.response == "success" && response.data.products_count == 1) {
                var product_info = response.data.products[0];
                this.image_show = false;
                this.youtube_show = false;
                this.vimeo_show = false;

                if(feature_id!=0){
                    var feature = product_info.features[0];
                    if(feature.image!=null){
                        this.img_src = window.baseUrl+"/storage/"+feature.image;
                        this.image_show = true;
                    }else {
                        this.loadVideo(feature.video);
                    }
                    this.pdf_url = window.baseUrl+"/storage/"+feature.pdf;
                    this.title_content = feature.title;
                    this.file_name = feature.title;
                    this.main_content = feature.description;
                }else{
                    var feature = product_info;
                    if(feature.image!=null){
                        this.img_src = window.baseUrl+"/storage/"+feature.image;
                        this.image_show = true;
                    }else {
                        this.loadVideo(feature.long_video_url);
                    }
                    this.pdf_url = window.baseUrl+"/storage/"+feature.pdf_file;
                    this.title_content = feature.title;
                    this.file_name = feature.title;
                    this.main_content = feature.description;
                }


                this.modalFlag = true;
                this.$store.dispatch("setModalShow", this.modalFlag);
                var vm = this;
                setTimeout(function() {
                  vm.modal_class = "aos-init aos-animate";
                }, 500);
            }
        });
    },
    loadVideo: function(url) {
        this.image_show = false;
        this.youtube_show = false;
        this.vimeo_show = false;

        if(url!=null && url.includes("vimeo")){
            this.vimeo_show = true;
            this.videoId = url;
        }else if(url!=null){
            this.videoId = this.youtubeParser(url);
            this.youtube_show = true;
        }
    },
    youtubeParser: function(url){
          var ID = '';
          // eslint-disable-next-line
          url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          if(url[2] !== undefined) {
            // eslint-disable-next-line
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
            var host = encodeURI(location.host);

            ID = 'https://www.youtube.com/embed/'+ID+'?enablejsapi=1&origin='+host+'&widgetid=1';
          }
          else {
            ID = url;
          }
            return ID;
    },
    stopVideo: function() {
        this.youtube_show = false;
        this.vimeo_show = false;
    },
    loadOverview: function() {
        var mval = this.machineFirstLoad;
        if (typeof mval.id !== 'undefined') {
            this.$store.dispatch("setInfoData", mval);
        }
    },
    scheduleDemo(){
        this.closePopup();
        this.$parent.$refs['ScheduleStepOne'].showPopup(true);
    },
    downloadPdf(){
        window.open(this.pdf_url, '_blank');
    },
    closePopup(){
        this.$store.dispatch("setProductContentLoaded", false);
        this.$store.dispatch("setProductContentFlagLoaded", false);
        this.image_show = false;
        this.youtube_show = false;
        this.vimeo_show = false;
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setModalShow", this.modalFlag);
    },
    postAnalytics(product_id,feature_id){
        axios
        .post(
          window.baseUrl+"/api/analytics/post",
          {
            user_id: this.$store.state.auth_user.id,
            product_id: product_id,
            feature_id: feature_id
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {

        });
    },
  },
};
</script>


