<template>
    <router-view />
</template>

<script>

import * as external from "./assets/external.js";


export default {
  name: "App",
  components: {

  },
  mounted() {
    external.head_link(window.host_url+"/css/circularMenu.css");
    external.head_link(window.host_url+"/css/style.css");
    // external.head_link(window.host_url+"/css/semantic.min.css");
    external.head_link(window.host_url+"/css/fontawesome.css");
    external.head_link(window.host_url+"/css/aos.css");
    external.head_link(window.host_url+"/css/circularMenu.css");

    external.body_script(window.host_url+"/js/aos.js");
    external.body_script(window.host_url+"/js/script.js");


    // external.body_script('./js/calendar_script.js');
    // external.body_script('./js/calendar_script.js');
// this.$store.dispatch("setCurrentState", "three");

    // external.body_script('./js/menu.js');
  },
  //  destroyed(){
  //     external.del_script('./js/aos.js');
  //     external.del_link('./css/aos.css');
  // },
  // setup() {
  //   const store = useStore();
  //   const currentState = computed(() => store.state.currentState);
  //   const sceneLoaded = computed(() => store.state.sceneLoaded);

  //   const changeSceneLoaded = () => store.dispatch("setSceneLoaded", true);

  //   return {
  //     currentState,
  //     sceneLoaded,
  //     changeSceneLoaded
  //   };
  // },

  // methods: {
  //   initThree() {
  //     threeScene = new ThreeScene();
  //   },
  //   loadInitalCube() {
  //     // threeScene.cubeMapLoad();
  //     // threeScene.loadRoom();
  //     threeScene.loadRoom();
  //   },
  //   loadMachine(machine) {
  //     // threeScene.loadMachine();
  //     this.$refs.showAgfaLoader.showLoader();
  //     threeScene.handleProductLoading(machine);
  //     this.$refs.showAgfaLoader.hideLoader();
  //   },
  //   clickedCont() {
  //     // this.$refs.showAgfaLoader.showLoader();
  //     if (this.sceneLoaded == true) {
  //       console.log(this.$refs);
  //     }
  //     this.$refs.enterEmail.showPopup(true);
  //   }
  // },

  // watch: {
  //   currentState: function(crrState, preState) {
  //     if (crrState === "three" && preState === "home") {
  //       this.initThree();
  //       this.loadInitalCube();
  //     }
  //   },
  //   sceneLoaded: function(crrState) {
  //     if (crrState) {
  //       this.$refs.showAgfaLoader.hideLoader();
  //     }
  //   }
  // }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'roboto-regular' !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

#threejs-container {
  /* width: 100vw !important;
  height: 100vh !important; */
  position: fixed;
  z-index: -1;
  top: 0;
  left:0px;
  width: calc(100% + 200px);
  display: none;
  transition: all 0.5s;
}
#threejs-container.push{
  left:-200px;
}

body {
  margin: 0 !important;
}

.patient-room-button {
  width: 80px;
  /* height: 50px; */
  margin-top: 20px;
  background-color: red;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}
small.error {
    color: red;
}
</style>
