<template>
 
				<Reschedule ref="Reschedule" />
	
	<div class="bg_div" style="background: #000;"><img src="/images/bg.jpg" alt="" class="w-100 bg_img" /></div>
</template>
<script>
import Reschedule from "../components/schedule/Reschedule.vue";
export default {
name: "Schedule",
  components: {
    Reschedule,
  },
  mounted(){
    this.$refs.Reschedule.showPopup(true);
  },
  beforeUnmount() {
    this.$store.dispatch("setSceneLoaded", false)
  }
}
</script>

<style>

</style>