import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import Schedule from "@/pages/Schedule.vue";
import JoinMeeting from "@/pages/JoinMeeting.vue";
import Reschedule from "@/pages/Reschedule.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Agfa Virtual Experience'
    },
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: Schedule,
    meta: {
      title: 'Agfa Virtual Experience | Schedule'
    },
  },
  {
    path: "/JoinMeeting/:id",
    name: "JoinMeeting",
    component: JoinMeeting,
    meta: {
      title: 'Agfa Virtual Experience | JoinMeeting'
    },
  },
  {
    path: "/reschedule/:id",
    name: "Reschedule",
    component: Reschedule,
    meta: {
      title: 'Agfa Virtual Experience | Reschedule'
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;
