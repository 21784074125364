const roomsData = [
  {
    id: "gallery-room",
    name: "Gallery Room",
    move: { x: 0, y: 0.8, z: 0 },
    camFov:70,
    spotlight: {
      distance: 25.56,
      angle: 0.354,
      penumbra: 0.22,
      decay: 0.7,
      pos: { x: 0, y: 6, z: 5.248 },
      targetPos: { x: 0, y: 0, z: 0 },
      intensity: 1
    },
    distance: {
      start: 2,
      min: 1.3,
      max: 2,
    },
    azimuth: {
      start: 0.2215188857018691,
      min: -Math.PI / 2,
      max: Math.PI / 2,
    },
    polar: {
      min: 1.04,
      max: 1.911060527670575,
      start: 1.5707963267948966,
    },
    backgroundUrl: "https://s3.amazonaws.com/agfa.assets/env-maps/env-2/",
    url: 'https://s3.amazonaws.com/agfa.assets/dr-room-v8/DR-Room-V8.glb',
    models: [
      {
        id: 9,
        name: "DR-800",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: -Math.PI / 2, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-model-v9/DR800_Rigged_Scaled_09b.glb",
        annotations: [
          {
            id: "9-41", //DR-80- smart technology
            pos: { x: 0.355, y: 0.877, z: -0.132 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "9-42", //DR-800-column movement
            pos: { x: 0.555, y: 0.388, z: -0.209 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "9-43", //DR-80- remote control
            pos: { x: 1.002, y: 0.470, z: -0.657 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "9-44", //DR-800-virtual collimation
            pos: { x: 0.12, y: 0.738, z: -0.102 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "9-45", //DR-800- musica
            pos: { x: 0.526, y: 0.516, z: 0.162 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
        ],
        info: {
          pos: { x: 0, y: 1.41, z: 0 },
          rot: { x: 0, y: -1.5708, z: 0 },
        },
      },
      {
        id: 6,
        name: "DX-D-300",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: 0, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-model-v9/DX-D_300_Rigged_Scaled_04.glb",
        annotations: [
          {
            id: "6-15", //DX-D-300-E-Optimal Flexibility
            pos: { x: -0.368, y: 0.868, z: 0.184 },
            rot: { x: 0, y: 0, z: 0 },
          },
          {
            id: "6-38", //DX-D-300-Universal Use
            pos: { x: -0.368, y: 0.675, z: 0.539 },
            rot: { x: 0, y: 0, z: 0 },
          },
          {
            id: "6-18", //DX-D-300-MUSICA Workstation
            pos: { x: 0.384, y: 0.903, z: 0.485 },
            rot: { x: 0, y: 0, z: 0 },
          },
        ],
        info: {
          pos: { x: 0, y: 1.42, z: 0 },
          rot: { x: 0, y: 0, z: 0 },
        },
      },
      {
        id: 7,
        name: "DR-400",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: -Math.PI / 2, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-model-v9/DR400_Rigged_Scaled09.glb",
        annotations: [
          {
            id: "7-22", //DX-400 Rotatable Bucky
            pos: { x: 0.172, y: 0.442, z: -0.306 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "7-19", //DR-400 Automatic Tracking
            pos: { x: 0.027, y: 0.645, z: 0.08 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "7-24", //DR-400 Wall Stand Collimator
            pos: { x: 0.203, y: 0.942, z: -0.863 },
            rot: { x: 0, y: 0.88662726, z: 0 },
          },
          {
            id: "7-21", //DR-400 Multi-functional Display
            pos: { x: 0.121, y: 0.942, z: -0.257 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "7-23", //DR-400 Under the Table Generator
            pos: { x: 0.172, y: 0.209, z: -0.04 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
        ],
        info: {
          pos: { x: 0, y: 1.45, z: -0.1 },
          rot: { x: 0, y: -1.5708, z: 0 },
        },
      },
      {
        id: 8,
        name: "DR-600",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: -Math.PI / 2, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-model-v9/DR600_Rigged_Scaled_08.glb",
        annotations: [
          {
            id: "8-27", //DR-600 work efficiently - DR-600 Auto Positioning
            pos: { x: 0.244, y: 1.491, z: 0.008 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "8-26", //DR-600 Easy & Light to Operate
            pos: { x: -0.005, y: 0.635, z: 0.273 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "8-39", //DR-600 Musica
            pos: { x: 0.172, y: 0.365, z: 0.053 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "8-27", //DR-600 work efficiently not working
            pos: { x: -0.005, y: 0.635, z: 0.273 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "8-40", //DR-600 Tracking
            pos: { x: 0.172, y: 0.495, z: -0.212 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
          {
            id: "8-29", //DR-600 full leg
            pos: { x: 0.585, y: 0.858, z: -1.378 },
            rot: { x: 0, y: 1.061160185, z: 0 },
          },
          {
            id: "8-25", // DR-600 Auto Positioning- DR-600 multi-functional display
            pos: { x: 0.105, y: 0.858, z: -0.269 },
            rot: { x: 0, y: -Math.PI / 2, z: 0 },
          },
        ],
        info: {
          pos: { x: 0.5, y: 1.7, z: -0.1 },
          rot: { x: 0, y: -1.5708, z: 0 },
        },
      }
    ],
  },
  {
    id: "dr-room",
    name: "DR Room",
    backgroundUrl:
      "https://s3.amazonaws.com/agfa.assets/images/dr-room-cubemap/",
    move: { x: -0.6, y: 1, z: 0.4 },
    camFov:70,
    spotlight: {
      distance: 8.5,
      angle: 0.4,
      penumbra: 0.32,
      decay: 0.1,
      pos: { x: 0.343, y: 4.217, z: 2.864 },
      targetPos: { x: -0.776, y: 0, z: 0.049 },
      intensity: 1
    },
    distance: { start: 1.9, min: 1.4, max: 1.9 },
    polar: { min: 0, max: 1.6534698176788385, start: 1.4081591750035498 },
    azimuth: {
      start: 0.3046891957610006,
      min: -1.4328627043895825,
      max: 1.2434613124300344,
    },
    url: "https://s3.amazonaws.com/agfa.assets/rooms/room-test.glb",
    models: [
      {
        id: 3,
        name: "DR-100-S",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: 0, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-models-v3/DR100s_Rigged_Scaled_06.glb",
        annotations: [
          {
            id: "3-2", //DR-100-S-designed-for-everyday-use
            pos: { x: -0.755, y: 0.379, z: 0.687 },
            rot: { x: 0, y: -0.6248278722, z: 0 },
          },
          {
            id: "3-4", //DR-100-S-freedom-of-positioning
            pos: { x: -1.317, y: 1.141, z: 0.119 },
            rot: { x: 0, y: -0.6248279, z: 0 },
          },
          {
            id: "3-3", //DR-100-S-bedside-intelligence
            pos: { x: -0.21, y: 1.25, z: 0.444 },
            rot: { x: 0, y: 0.9738936999996796, z: 0 },
          },
          {
            id: "3-1", //DR-100-S-agile-DR-solution
            pos: { x: -0.134, y: 0.787, z: 0.371 },
            rot: { x: 0, y: 0.9738937226, z: 0 },
          },
          {
            id: "3-5", //DR-100-S-MUSICA-workstation
            pos: { x: -0.671, y: 1.063, z: 0.71 },
            rot: { x: 0, y: 0.6492624817, z: 0 },
          },
        ],
        info: {
          pos: { x: -0.442, y: 1.7, z: 0.431 },
          rot: { x: 0, y: 1.054178868, z: 0 },
        },
      },
      {
        id: 4,
        name: "DR-100-E",
        pos: { x: 0, y: 0, z: 0 },
        rot: { x: 0, y: 0, z: 0 },
        url:
          "https://s3.amazonaws.com/agfa.assets/machine-models-v3/D100e_Rigged_Scaled_13.glb",
        annotations: [
          {
            id: "4-7", //DR-100-E-improves-workflow
            pos: { x: -0.268, y: 0.768, z: 0.805 },
            rot: { x: 0, y: 1.054178868, z: 0 },
          },
          {
            id: "4-6", //DR-100-E-easy-to-maneuver
            pos: { x: -0.694, y: 0.396, z: 0.707 },
            rot: { x: 0, y: -0.5061454831, z: 0 },
          },
          {
            id: "4-9", //DR-100-E-versatile-efficient
            pos: { x: -0.224, y: 1.232, z: 0.382 },
            rot: { x: 0, y: 1.054178868, z: 0 },
          },
          {
            id: "4-10", //DR-100-E-MUSICA-workstation
            pos: { x: -0.304, y: 1.676, z: 0.257 },
            rot: { x: 0, y: 1.054178868, z: 0 },
          },
          {
            id: "4-8", //DR-100-E-easy-to-position
            pos: { x: -0.887, y: 0.861, z: 0.299 },
            rot: { x: 0, y: -0.5061454831, z: 0 },
          },
        ],
        info: {
          pos: { x: -0.442, y: 1.93, z: 0.431 },
          rot: { x: 0, y: 1.054178868, z: 0 },
        },
      },
      {
        id: 5,
        name: "DX-D-100",
        pos: { x: -0.847, y: 0.1, z: 0.391 },
        rot: { x: 0, y: 0.2862339973, z: 0 },
        url: "https://s3.amazonaws.com/agfa.assets/machine-model-v9/DX-D-100_Extended.glb",
        annotations: [
          {
            id: "5-13", //Baging made easy
            pos: { x:-0.127, y: 0.866, z: 0.499 },
            rot: { x: 0, y: 0.2303834613, z: 0 },
          },
          {
            id: "5-11", // Smart storage
            pos: { x: -0.010, y: 0.452, z: 0.461 },
            rot: { x: 0, y: 0.2303834613, z: 0 },
          },
          {
            id: "5-12", // Move freely
            pos: { x: 0.543, y: 1.169, z: 0.312 },
            rot: { x: 0, y: 0.2303834613, z: 0 },
          },
          {
            id: "5-37", // Power at Hand
            pos: { x: 0.290, y: 0.697, z: 0.392 },
            rot: { x: 0, y: 0.2303834613, z: 0 },
          },
          {
            id: "5-36", // Control Where You Need It
            pos: { x: 0.144, y: 0.899, z: 0.391},
            rot: { x: 0, y: 0.2303834613, z: 0 },
          },
        ],
        info: {
          pos: { x: 0.074, y: 1.599, z: 0.470 },
          rot: { x: 0, y: 0.2303834613, z: 0 },
        },
      },
    ],
  },
];

export default roomsData;
