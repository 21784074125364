<template>
  <header class="top_header w-100" v-if="0">
    <nav class="top_rig_menu">
        <ul class="text-center float-right">
            <li>
                <a href="">
                    <img src="/images/logo.svg" class="img-fluid " alt="" />
                </a>
            </li>
        </ul>
    </nav>
</header>
  <div class="main-aria scheduler_content" v-if="modalFlag">
    <div class="container-fluid">
      <div class="row justify-content-center " >
        <div
          class="col-md-11 col-lg-9 col-xl-6 item  "
          :class="modal_class"
          data-aos="fade-up"
        >
          <div class="login_box p-5 text-center">
            <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup()"><i class="far fa-times-circle"></i></a></div>
            <div class="row row justify-content-center">
                <div class="col-md-10 mb-4  text-center">
                    <h4>&nbsp</h4>
                </div>
                <div class="col-md-12 text-center">
                    <img src="/images/logo.svg" class="img-fluid mb-5" alt="" />
                    <h3>Which Product Would  You Like To Demo?</h3>
                        <div class="form-group my-5 change_b">
                        <select class="form-control select_Product" name="product_id" id="product" required="" v-model="product" >
                            <option :value="{}">Select Product</option>
                            <option :value="product" v-for="product in products" v-bind:key="product.id">{{ product.name }}</option>
                        </select>
                        <small class="error" v-if="errors['product']">{{errors['product']}}</small>
                        </div>
                        <div class="form-group">
                            <a type="button" class="btn login_btn mb-2" href="#" @click="notSure()">Not Sure?</a>
                            <button type="button" class="btn login_btn mb-2" id="next_page" @click="nextStep()">Next</button>
                        </div>
                        <div class="form-group">
                            &nbsp;
                        </div>
                        <div class="form-group">
                            <small class="float-right">*Works best in Edge, Chrome or Safari</small>
                        </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
	</section> -->
  <RequestDemoComponent ref="RequestDemoComponent" />
</template>

<script>
import axios from 'axios';
import RequestDemoComponent from "./RequestDemoComponent.vue";

export default {
  name: "Products",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      products: [],
      countries: [],
      product : {},
      errors : [],
    };
  },
  components: {
    RequestDemoComponent
  },
  mounted() {
  },
  created(){
  },
  computed: {
    productDetailsFlag(){
      return this.$store.state.productContentFlagLoaded;
    }
  },
  methods: {
    // login

    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        this.modalFlag = val;
        this.$store.dispatch("setMenuFlagLoaded", false);
        return;
      }
      this.$store.dispatch("setLoadingSpinner", true);

      var vm = this;
       axios
        .get(
          window.baseUrl+"/api/scheduler/data")
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);

          if (response.data.response == "success") {
            this.modalFlag = val;
            this.$store.dispatch("setMenuFlagLoaded", true);
            this.products = response.data.products;
            this.countries = response.data.countries;
            setTimeout(function() {
              vm.modal_class = "aos-init aos-animate";
            }, 500);
          }
        });

    },
    closePopup(){
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setMenuFlagLoaded", false);
        this.$store.dispatch("setMenuClick", false);
    },
    nextStep(){
      this.errors = [];
      if(!this.product.id ){
        this.errors['product'] = 'please select a product';
        return;
      }
      this.showPopup(false)
      this.$refs.RequestDemoComponent.getProductData(this.product.id);
      this.$refs.RequestDemoComponent.showPopup(true);
      this.$refs.RequestDemoComponent.countries = this.countries;
      this.$refs.RequestDemoComponent.product_name = this.product.name;
      this.$refs.RequestDemoComponent.product_id = this.product.id;
      var cntr = this.countries.filter(word => word.name == 'UNITED STATES')
      this.$refs.RequestDemoComponent.demo.country = cntr[0].name;
    },
    getProducts(){
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/scheduler/data")
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.products = response.data.products;
            if(this.products.length > 0){
                this.product = this.products[0];
            }
            this.countries = response.data.countries;
          }
        });
    },
    notSure(){
       window.open("mailto:RADmarketing@agfa.com?subject=RSNA 2020 request for information", '_blank');
    },
    closeSecondPopUp(){
       this.$refs.RequestDemoComponent.closePopup();
    },
  },
  watch:{
    productDetailsFlag(val){
      if(val == true){
        this.closePopup()
      }
    }
  }
};
</script>
