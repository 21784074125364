// import * as THREE from "three";

import {
  TextureLoader,
  MeshBasicMaterial,
  BackSide,
  DoubleSide,
  FrontSide,
  BoxGeometry,
  CylinderGeometry,
  Mesh,
  CubeTextureLoader,
  SpotLight,
  AmbientLight,
  PlaneGeometry,
  Color,
  AnimationMixer,
  Object3D,
  SpriteMaterial,
  Sprite,
  ACESFilmicToneMapping,
  sRGBEncoding,
  NormalBlending,
  CircleBufferGeometry,
  RectAreaLight,
  NoToneMapping,
  LinearEncoding,
} from "three";

import { Reflector } from "three/examples/jsm/objects/Reflector.js";

import ThreeMain from "./main";
import store from "../store";
import cubeMapData from "./cubeMapData";
import roomsData from "./3droomData";

class ThreeScene extends ThreeMain {
  constructor() {
    super();
    const geometry = new CylinderGeometry(9, 15, 1, 32);
    const material = new MeshBasicMaterial({
      color: 0x2194ce,
      opacity: 0.4,
      visible: true,
      transparent: true,
      side: FrontSide,
    });
    this.cylinder = new Mesh(geometry, material);
    this.cylinder.scale.set(0.3, 0.3, 0.3);

    const geometryBlack = new CylinderGeometry(9, 15, 1, 32);
    const materialBlack = new MeshBasicMaterial({
      color: "black",
      opacity: 0.4,
      visible: true,
      transparent: true,
      side: FrontSide,
    });
    this.cylinderBlack = new Mesh(geometryBlack, materialBlack);
    this.cylinderBlack.scale.set(0.3, 0.3, 0.3);

    var materialAnnotation = new SpriteMaterial({});

    new TextureLoader().load("hotspot.png", (tex) => {
      materialAnnotation.map = tex;
      this.annotation = new Sprite(materialAnnotation);
      this.annotation.scale.set(0.1, 0.1, 0.1);
    });

    var materialInfo = new SpriteMaterial();

    new TextureLoader().load("hotspot.png", (tex) => {
      materialInfo.map = tex;
      this.info = new Sprite(materialInfo);
      this.info.scale.set(0.1, 0.1, 0.1);
    });

    this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
    this.onHover = this.onHover.bind(this);
    window.addEventListener("click", this.onDocumentMouseDown, false);
    window.addEventListener("mousemove", this.onHover, false);
    window.addEventListener("touchend", this.onDocumentMouseDown, false);

    const width = 5.451;
    const height = 0.427;
    const intensity = 1;
    this.rectLight = new RectAreaLight(0xffffff, intensity, width, height);
  }

  onHover(event) {
    event.preventDefault();

    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(this.mouse, this.camera);

    let clickHotspot = this.scene.getObjectByName("hotspots");

    if (clickHotspot) {
      let intersects = this.raycaster.intersectObjects(clickHotspot.children);
      if (intersects.length > 0) {
        $("html,body").css("cursor", "pointer");
      } else {
        $("html,body").css("cursor", "default");
      }
    }
  }

  loadTexture(url, transpancy) {
    return new Promise((resolve, reject) => {
      new TextureLoader().load(
        url,
        (texture) => {
          let material = new MeshBasicMaterial({
            map: texture,
            side: BackSide,
            transparent: true,
            opacity: transpancy,
            depthWrite: false,
          });
          resolve(material);
        },
        (xhr) => {
          let total = (xhr.loaded / xhr.total) * 100;
          console.log(total);
        },
        () => {
          reject();
        }
      );
    });
  }

  loadImageModel(machine, roomName) {
    this.animationPlay && this.stopAnimation();
    store.dispatch("setInfoData", {});
    const { url, pos, rot, id, name, annotations } = machine;
    let machineInfo = machine.info;

      return new Promise((resolve, reject) => {
        this.gltfLoader.load(
          url,
          (gltf) => {
            let model = gltf.scene;

            model.traverse((child) => {
              child.castShadow = true;
              if (child.material) {
                child.castShadow = true;
                // child.receiveShadow = true;
              }
            });

        model.name = "machine";
        model.position.set(pos.x, pos.y, pos.z);
        model.rotation.set(rot.x, rot.y, rot.z);
        model.scale.set(1, 1, 1);

        // Annotations
        let annotationsGroup = this.group.clone();
        annotationsGroup.name = "annotations";
        for (const obj of annotations) {
          var materialAnnotation = new MeshBasicMaterial({
            side: DoubleSide,
            transparent: true,
          });
          var geometryAnnotation = new PlaneGeometry();

          new TextureLoader().load("hotspot.png", (tex) => {
            materialAnnotation.map = tex;
            let annotation = new Mesh(geometryAnnotation, materialAnnotation);
            annotation.scale.set(0.08, 0.08, 0.08);

            annotation.name = "annotation";
            annotation.userData = { id: obj.id };
            annotation.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
            annotation.rotation.set(obj.rot.x, obj.rot.y, obj.rot.z);
            annotationsGroup.add(annotation);
          });
        }
        model.add(annotationsGroup);

        let spotlight = this.scene.getObjectByName("spotlight");
        let spotlightTarget = this.scene.getObjectByName("spolight-target");
        let ambientlight = this.scene.getObjectByName("ambient-light")
        ambientlight.intensity = 1
        spotlight.visible = false;
        spotlightTarget.visible = false;

        let info = this.info.clone();
        info.name = "info";
        info.userData = { id, name };
        info.scale.set(0.08, 0.08, 0.08);
        info.position.set( machineInfo.pos.x,
          machineInfo.pos.y,
          machineInfo.pos.z
        );
        info.rotation.set(
          machineInfo.rot.x,
          machineInfo.rot.y,
          machineInfo.rot.z
        );
        model.add(info);

        let room = this.scene.getObjectByName(roomName);
        room.add(model);

        this.renderer.render(this.scene, this.camera);

        this.isAnnotations = true;

        store.dispatch("setActiveModel", {
          id,
          name,
        });

        resolve();
      });
    });
  }

  generateCubeMap(sceneData, transpancy) {
    return new Promise((resolve, reject) => {
      let { position, rotation, urls, hotspots, icons } = sceneData;
      let urlsVals = Object.values(urls);
      let loadText = urlsVals.map((x) => this.loadTexture(x, transpancy));
      var geometry = new BoxGeometry(100, 100, 100);

      this.cameraControls.maxPolarAngle = sceneData.polar.max;
      this.cameraControls.minPolarAngle = sceneData.polar.min;
      this.cameraControls.polarAngle = sceneData.polar.start;

      this.cameraControls.azimuthAngle = sceneData.azimuth.start;
      this.cameraControls.minAzimuthAngle = sceneData.azimuth.min;
      this.cameraControls.maxAzimuthAngle = sceneData.azimuth.max;

      this.cameraControls.minDistance = sceneData.distance.min;
      this.cameraControls.maxDistance = sceneData.distance.max;
      this.cameraControls.distance = sceneData.distance.start;

      Promise.all(loadText)
        .then((materials) => {
          var cubeMesh = new Mesh(geometry, materials);
          cubeMesh.name = "cubemap";
          cubeMesh.scale.set(-1, 1, 1);
          cubeMesh.position.set(position.x, position.y, position.z);
          cubeMesh.rotation.set(rotation.x, rotation.y, rotation.z);

          let hotspotGroup = this.group.clone();
          hotspotGroup.name = "hotspots";
          hotspotGroup.scale.set(-1, 1, 1);

          let annotationsGroup = this.group.clone();
          annotationsGroup.name = "atrium-icon";
          annotationsGroup.scale.set(-1, 1, 1);

          for (const icon of icons) {
            const map = new TextureLoader().load("hotspot.png");
            const geometry = new CylinderGeometry(1.2, 1.2, 0.1, 64);
            const material = new MeshBasicMaterial({
              map: map,
              side: DoubleSide,
              transparent: true,
              blending: NormalBlending,
              alphaTest: 0.5,
            });
            const atriumIcon = new Mesh(geometry, material);
            atriumIcon.position.set(icon.pos.x, icon.pos.y, icon.pos.z);
            atriumIcon.rotation.set(icon.rot.x, icon.rot.y, icon.rot.z);
            annotationsGroup.add(atriumIcon);
          }

          cubeMesh.add(annotationsGroup);

          for (const obj of hotspots) {
            const geometry = new PlaneGeometry(5, 20, 32);
            const material = new MeshBasicMaterial({
              color: 0xffff00,
              side: DoubleSide,
            });
            const hotspot = new Mesh(geometry, material);
            hotspot.visible = false;

            hotspot.position.set(
              obj.arrowposs.x,
              obj.arrowposs.y,
              obj.arrowposs.z
            );

            hotspot.rotation.set(
              obj.rotation.x,
              obj.rotation.y,
              obj.rotation.z
            );

            hotspot.scale.set(obj.scale.x, obj.scale.y, obj.scale.z);
            hotspot.userData = obj.id;
            hotspotGroup.add(hotspot);
          }

          cubeMesh.add(hotspotGroup);
          this.scene.add(cubeMesh);
          resolve(position);
        })
        .catch(() => {
          reject();
        });
    });
  }

  loadCubeMapInit() {
    this.generateCubeMap(cubeMapData, 1).then((position) => {
      this.cameraControls.moveTo(position.x, position.y, position.z, false);
      this.renderer.render(this.scene, this.camera);
      this.animate();
      store.dispatch("setSceneLoaded", true);
      store.dispatch("setLoadingSpinner", false);
      document.getElementById("threejs-container").style.display = "block";
    });
  }

  async loadCubeMapFromRoom() {
    const { sceneLoaded, isCubeMap, isGallery, isDrRoom } = store.state;
    if (sceneLoaded && !isCubeMap) {
      this.animationPlay && this.stopAnimation();
      store.dispatch("setInfoData", {});
      store.dispatch("setLoadingSpinner", true);
      this.generateCubeMap(cubeMapData, 1).then(async (position) => {
        this.cameraControls.moveTo(position.x, position.y, position.z, false);

        this.renderer.toneMapping = NoToneMapping;
        this.renderer.toneMappingExposure = 0.74;
        this.renderer.outputEncoding = LinearEncoding;

        isGallery && this.disposeRoom("Gallery Room");
        isDrRoom && this.disposeRoom("DR Room");

        store.dispatch("setCurrentRoom", {
          isCubeMap: true,
          isDrRoom: false,
          isGallery: false,
        });

        this.renderer.render(this.scene, this.camera);
        store.dispatch("setLoadingSpinner", false);
      });
    }
  }

  disposeCube() {
    let cubemap = this.scene.getObjectByName("cubemap");
    cubemap.geometry.dispose();
    for (let index = 0; index < 6; index++) {
      cubemap.material[index].map.dispose();
      cubemap.material[index].dispose();
    }
    this.scene.remove(cubemap);
  }

  onDocumentMouseDown(event) {
    event.preventDefault();
    const { isCubeMap, menuClick, loadingSpinner } = store.state;
    if (!loadingSpinner && !menuClick) {
      if (event.changedTouches) {
        this.mouse.x = (event.changedTouches[0].pageX / this.width) * 2 - 1;
        this.mouse.y = -(event.changedTouches[0].pageY / this.height) * 2 + 1;
      } else {
        this.mouse.x = (event.clientX / this.width) * 2 - 1;
        this.mouse.y = -(event.clientY / this.height) * 2 + 1;
      }

      this.raycaster.setFromCamera(this.mouse, this.camera);

      let clcikAnnotations = this.scene.getObjectByName("annotations");

      let clickOnInfo = this.scene.getObjectByName("info");

      if (clickOnInfo) {
        let intersects = this.raycaster.intersectObject(clickOnInfo);
        if (intersects.length > 0) {
          let infoData = intersects[0].object.userData;
          store.dispatch("setInfoData", infoData);
          console.log(infoData);
        }
      }

      if (clcikAnnotations) {
        let intersects = this.raycaster.intersectObjects(
          clcikAnnotations.children
        );
        if (intersects.length > 0) {
          let eveId = intersects[0].object.userData;
          store.dispatch("setAnnotationActiveId", eveId.id);
          console.log(eveId);
        }
      }

      if (isCubeMap && !menuClick) {
        let clickHotspot = this.scene.getObjectByName("hotspots");
        let intersects = this.raycaster.intersectObjects(clickHotspot.children);
        if (intersects.length > 0) {
          let bannerId = intersects[0].object.userData;
          store.dispatch("setAtriumBannerClick", bannerId);
        }
      }
    }
  }

  sceneBackground(imgURL) {
    return new Promise((reslove, reject) => {
      new CubeTextureLoader()
        .setPath(imgURL)
        .load(
          ["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"],
          (tex) => {
            // tex.encoding = sRGBEncoding;
            // tex.mapping = EquirectangularReflectionMapping;
            tex.needsUpdate = true;
            this.scene.background = tex;
            this.scene.environment = tex;
            tex.needsUpdate = true;
            this.renderer.render(this.scene, this.camera);
            reslove();
          }
        );
    });
  }

  disposeRoom(name) {
    return new Promise((resolve, reject) => {
      let room = this.scene.getObjectByName(name); // room
      let product = room && room.getObjectByName("machine"); // models
      let annotations = product && product.getObjectByName("annotations"); // annotations
      let info = product && product.getObjectByName("info");

      // remove lights
      let spotlight = this.scene.getObjectByName("spotlight");
      let spotlightTarget = this.scene.getObjectByName("spolight-target");

      let ambientlight = this.scene.getObjectByName("ambient-light");
      ambientlight && this.scene.remove(ambientlight);
      if (name === "Gallery Room" && room) {
        let rectLight1 = room.getObjectByName("rectlight1");
        let rectlight2 = room.getObjectByName("rectlight2");
        let rectLight3 = room.getObjectByName("rectLight3");
        let rectLight4 = room.getObjectByName("rectLight4");
        room.remove(rectLight1);
        room.remove(rectlight2);
        room.remove(rectLight3);
        room.remove(rectLight4);

        let reflector = room.getObjectByName("reflector");
        room.remove(reflector);
      }

      this.isAnnotations = false;

      spotlight && this.scene.remove(spotlight);
      spotlightTarget && this.scene.remove(spotlightTarget);

      product && product.remove(annotations);
      product && product.remove(info);
      room && room.remove(product);
      this.scene.remove(room);
      resolve();
    });
  }

  addSpotLightToRoom({ distance, angle, penumbra, decay, pos, targetPos, intensity }) {
    let spotlight = new SpotLight(
      0xffffff,
      intensity,
      distance,
      angle,
      penumbra,
      decay
    );
    spotlight.name = "spotlight";

    spotlight.position.set(pos.x, pos.y, pos.z);
    spotlight.castShadow = true;

    let targetObj = new Object3D();
    targetObj.name = "spolight-target";
    targetObj.position.set(targetPos.x, targetPos.y, targetPos.z);

    spotlight.target = targetObj;

    this.scene.add(spotlight.target);
    this.scene.add(spotlight);
  }

  loadRoom(name) {
    const { isCubeMap, isDrRoom, isGallery } = store.state;
    if (isCubeMap) {
      this.renderer.toneMapping = ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 0.74;
      this.renderer.outputEncoding = sRGBEncoding;
    }
    let sameRoom =
      (name === "DR Room" && isDrRoom) ||
      (name === "Gallery Room" && isGallery);
    if (!sameRoom) {
      this.animationPlay && this.stopAnimation();
      let roomData = roomsData.find((x) => x.name === name);
      this.gltfLoader.load(
        roomData.url,
        async (gltf) => {
          let model = gltf.scene;
          model.traverse((child) => {
            if (child.material) {
              child.material.vertexColors = true;
            }
            if (child.name == "Wall_Floor_01") {
              child.material.vertexColors = false;
              child.material.color = new Color(0x1f1f1f);
              child.receiveShadow = true;
            }
            if (child.name == "Gallery_Room_V001001") {
              child.material.vertexColors = false;
              child.receiveShadow = true;
              child.material.transparent = true;
              child.material.roughness = 0.60
              child.material.opacity = 0.7;
            }

            if (child.name === "Gallery_Room_V001002")
              child.material.vertexColors = false;
          });

          // Lights to room
          this.addSpotLightToRoom(roomData.spotlight);

          var ambient = new AmbientLight(0xffffff, 0.28);
          ambient.name = "ambient-light";
          this.scene.add(ambient);

          if (name === "Gallery Room") {
            let rectLight1 = this.rectLight.clone();
            rectLight1.position.set(-0.063, 2.6, -2.27);
            rectLight1.rotateX(3.14159);
            rectLight1.lookAt(0, 0, 0);
            rectLight1.name = "rectlight1";
            model.add(rectLight1);

            let rectLight2 = this.rectLight.clone();
            rectLight2.position.set(-0.061, 2.6, 2.205);
            rectLight2.lookAt(0, 0, 0);
            rectLight2.name = "rectlight2";
            model.add(rectLight2);

            let rectLight3 = this.rectLight.clone();
            rectLight3.position.set(-3.007, 2.6, -0.047);
            rectLight3.rotateY(Math.PI / 2);
            rectLight3.lookAt(0, 0, 0);
            rectLight3.name = "rectlight3";
            model.add(rectLight3);

            let rectLight4 = this.rectLight.clone();
            rectLight4.position.set(2.888, 2.6, -0.047);
            rectLight4.rotateY(-Math.PI / 2);
            rectLight4.lookAt(0, 0, 0);
            rectLight4.name = "rectlight4";
            model.add(rectLight4);

            let geometryRef = new CircleBufferGeometry(40, 64);
            const groundMirror = new Reflector(geometryRef, {
              clipBias: 0.003,
              textureWidth: window.innerWidth * window.devicePixelRatio,
              textureHeight: window.innerHeight * window.devicePixelRatio,
              color: 0x777777,
            });
            groundMirror.position.y = 0.09;
            groundMirror.rotateX(-Math.PI / 2);
            groundMirror.name = "reflector";
            model.add(groundMirror);
          }

          model.name = name;

          await this.sceneBackground(roomData.backgroundUrl);

          this.scene.add(model);

          this.cameraControls.maxPolarAngle = roomData.polar.max;
          this.cameraControls.minPolarAngle = roomData.polar.min;
          this.cameraControls.polarAngle = roomData.polar.start;

          this.cameraControls.azimuthAngle = roomData.azimuth.start;
          this.cameraControls.minAzimuthAngle = roomData.azimuth.min;
          this.cameraControls.maxAzimuthAngle = roomData.azimuth.max;

          this.cameraControls.minDistance = roomData.distance.min;
          this.cameraControls.maxDistance = roomData.distance.max;
          this.cameraControls.distance = roomData.distance.start;

          this.cameraControls.moveTo(
            roomData.move.x,
            roomData.move.y,
            roomData.move.z,
            false
          );
          this.camera.fov = roomData.camFov;
          this.camera.updateProjectionMatrix();

          isCubeMap && this.disposeCube();

          if (name === "DR Room" && !isCubeMap) {
            await this.disposeRoom("Gallery Room");
          }

          if (name === "Gallery Room" && !isCubeMap) {
            await this.disposeRoom("DR Room");
          }

          store.dispatch("setCurrentRoom", {
            isCubeMap: false,
            isDrRoom: name === "DR Room",
            isGallery: name === "Gallery Room",
          });

          store.dispatch("setLoadingPercentage", 0);

          this.loadProduct(roomData.models[0], name).then(() => {
            this.renderer.render(this.scene, this.camera);
            store.dispatch("setLoadingSpinner", false);
          });
        },
        (xhr) => {
          let total = Math.round((xhr.loaded / xhr.total) * 100);
          store.dispatch("setLoadingPercentage", total);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      store.dispatch("setLoadingSpinner", false);
      console.log(
        "Cant do nothing for this option because you clicked same room"
      );
      // return;
    }
  }

  activateAnimation() {
    store.dispatch("setAnimationPlay", true);
    this.animationPlay = true;

    let annotations = this.scene.getObjectByName("annotations");
    if (annotations) annotations.visible = false;

    let info = this.scene.getObjectByName("info");
    if (info) info.visible = false;

    this.animationClips.forEach((clip) => {
      this.mixer.clipAction(clip).play();
    });
  }

  stopAnimation() {
    store.dispatch("setAnimationPlay", false);
    this.animationPlay = false;

    let annotations = this.scene.getObjectByName("annotations");
    if (annotations) annotations.visible = true;

    let info = this.scene.getObjectByName("info");
    if (info) info.visible = true;

    this.animationClips.forEach((clip) => {
      this.mixer.clipAction(clip).stop();
    });
  }

  loadProduct(machine, roomName) {
    this.animationPlay && this.stopAnimation();
    store.dispatch("setInfoData", {});
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(
        machine.url,
        (gltf) => {
          let model = gltf.scene;
          let animation = gltf.animations;

          this.mixer = new AnimationMixer(model);
          this.animationClips = [];
          for (let i = 0; i < animation.length; i++) {
            if (machine.name === "DR-100-E" && animation[i].name === "rp_janna_rigged_HIK_arnold:renderpeople_janna_rigged|Take 0.001" && i === 17) continue;
            this.animationClips.push(animation[i]);
          }

          model.traverse((child) => {
            child.castShadow = true;
            if (child.material) {
              child.castShadow = true;
              // child.receiveShadow = true;
            }
          });

          model.name = "machine";

          model.position.set(machine.pos.x, machine.pos.y, machine.pos.z);
          model.rotation.set(machine.rot.x, machine.rot.y, machine.rot.z);

          // Annotations
          let annotationsGroup = this.group.clone();
          annotationsGroup.name = "annotations";
          for (const obj of machine.annotations) {
            let annotation = this.annotation.clone();
            annotation.name = "annotation";
            annotation.userData = { id: obj.id };
            annotation.position.set(obj.pos.x, obj.pos.y, obj.pos.z);
            annotation.rotation.set(obj.rot.x, obj.rot.y, obj.rot.z);
            annotationsGroup.add(annotation);
          }
          model.add(annotationsGroup);

          let info = this.info.clone();
          info.name = "info";
          info.userData = { id: machine.id, name: machine.name };
          info.position.set(
            machine.info.pos.x,
            machine.info.pos.y,
            machine.info.pos.z
          );
          info.rotation.set(
            machine.info.rot.x,
            machine.info.rot.y,
            machine.info.rot.z
          );
          model.add(info);

          let room = this.scene.getObjectByName(roomName);

          if (roomName === "DR Room") {
            let spotlight = this.scene.getObjectByName("spotlight");
            let spotlightTarget = this.scene.getObjectByName("spolight-target");
            let ambientlight = this.scene.getObjectByName("ambient-light")
            if (machine.name === "DX-D-100") {
              spotlight.visible = false;
              spotlightTarget.visible = false;
              ambientlight.intensity = 5
              ambientlight = true
            } else {
              spotlight.visible = true;
              spotlightTarget.visible = true;
              ambientlight.intensity = 0.28
            }
          }

          room.add(model);

          this.renderer.render(this.scene, this.camera);

          this.isAnnotations = true;

          store.dispatch("setActiveModel", {
            id: machine.id,
            name: machine.name,
          });

          resolve();
        },
        (xhr) => {
          let total = Math.round((xhr.loaded / xhr.total) * 100);
          store.dispatch("setLoadingPercentage", total);
        },
        (err) => {
          console.log(err);
          reject();
        }
      );
    });
  }

  handleProductLoading({ name, isGallery }) {
    let indexOfParent = isGallery ? 0 : 1;
    let modelData = roomsData[indexOfParent].models.find(
      (x) => x.name === name
    );
    let { polar, azimuth } = roomsData[indexOfParent];
    let roomName = isGallery ? "Gallery Room" : "DR Room";
    this.loadProduct(modelData, roomName).then(() => {
      this.cameraControls.polarAngle = polar.start;
      this.cameraControls.minPolarAngle = polar.min;
      this.cameraControls.maxPolarAngle = polar.max;

      this.cameraControls.azimuthAngle = azimuth.start;
      this.cameraControls.minAzimuthAngle = azimuth.min;
      this.cameraControls.maxAzimuthAngle = azimuth.max;

      let room3D = this.scene.getObjectByName(roomName);
      let model = room3D.getObjectByName("machine");
      room3D.remove(model);

      this.renderer.render(this.scene, this.camera);
      store.dispatch("setLoadingSpinner", false);
    });
  }

  handleImageProductLoading() {
    let test = roomsData[1].models[2];
    let { polar, azimuth } = roomsData[1];
    this.loadImageModel(test, "DR Room").then(() => {

      this.cameraControls.polarAngle = 1.5556832697292178;
      this.cameraControls.minPolarAngle = 1.5556832697292178;
      this.cameraControls.maxPolarAngle = 1.5556832697292178;

      this.cameraControls.azimuthAngle = 0.546092259857548;
      this.cameraControls.minAzimuthAngle = 0.546092259857548;
      this.cameraControls.maxAzimuthAngle = 0.546092259857548;

      this.cameraControls.minDistance = 1.8
      this.cameraControls.maxDistance = 1.8;
      this.cameraControls.distance = 1.8;


      let room3D = this.scene.getObjectByName("DR Room");
      let model = room3D.getObjectByName("machine");
      room3D.remove(model);

      this.renderer.render(this.scene, this.camera);
      store.dispatch("setLoadingSpinner", false);
    });
  }
}

export default ThreeScene;
