<template>
    <header class="top_header w-100" >
        <nav class="top_rig_menu">
            <ul class="text-center float-right">
                <li>
                    <a href="https://medimg.agfa.com/main/direct-radiography" target="_blank">
                        <img src="/images/logo.svg" class="img-fluid " alt="" />
                    </a>
                </li>
            </ul>
        </nav>
    </header>
 <div class="main-aria scheduler_content">
    <div class="container-fluid">
        <div class="row justify-content-center" v-if="firstModalFlag">
            <div class="col-md-11 col-lg-9 col-xl-8 item"
            :class="modal_class"
            data-aos="fade-up">
                <div class="login_box p-5 text-center">
                  <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup(1)"><i class="far fa-times-circle"></i></a></div>
                    <div class="row row justify-content-center">
                        <div class="col-md-12 text-center">
                            <img src="/images/logo.svg" class="img-fluid mb-5" alt="" />
                            <h3>Which Product Would  You Like To Demo?</h3>
                                <div class="form-group my-5 change_b">
                                <select class="form-control select_Product" name="product_id" id="product" required="" v-model="product" >
                                  <option value="">Select Product</option>
                                  <option :value="product" v-for="product in products" v-bind:key="product.id">{{ product.name }}</option>
                              </select>
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn login_btn" id="next_page" @click="next()">Next</button>
                                </div>
                                <div class="form-group">
                                    &nbsp;
                                </div>
                                <div class="form-group">
                                    <small class="float-right">*Works best in Edge, Chrome or Safari</small>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" v-if="secondModalFlag">
            <div class="col-md-11 col-lg-9 col-xl-8 item"
            :class="modal_class"
            data-aos="fade-up">

                <div class="login_box p-5">
                  <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup(2)"><i class="far fa-times-circle"></i></a></div>
                    <div class="row row justify-content-center">
                        <div class="col-md-10 mb-4  text-center"><h4>&nbsp</h4></div>
                        <div class="col-md-6 text-center">
                            <img src="/images/logo.svg" class="img-fluid" alt="" />

                              <div class="form-group mt-5">
                                  <select class="form-control sche_sel select_Product" id="date_slot" name="date_slot" required="" v-model="selected_date">
                                      <option value="">Select Date</option>
                                      <option :value="title" v-for="title in dateSlotes" v-bind:key="title">{{ title }}</option>
                                  </select>
                                  <small class="error" v-if="errors['date_slot']"> {{errors['date_slot']}}</small>
                            </div>
                            <div class="form-group my-2">
                              <select class="form-control sche_sel select_Product" id="time_slot" name="time_slot" required="" v-model="selected_time" @change="timeChanged($event)">
                                  <option value="">Select Time</option>
                                  <option :value="time.id" v-for="time in changeTimeSlotes" v-bind:key="time">{{ changeTimes(time)}}</option>

                              </select>
                              <small class="error" v-if="errors['time_slot']"> {{errors['time_slot']}}</small>
                            </div>
                            <div class="form-group my-2 text-right">
                                <small id="timezone_user">
                                  {{timezone}}
                                </small>
                            </div>
                                <div class="form-group mt-5 datetimepicker_view">
                                    <date-input ref="rescheduledatePicker"  @selectedagfaDate="dateSelected($event)" :value="selected_date" v-if="rescheduleFlag"></date-input>
                                </div>

                        </div>
                        <div class="col-md-6">
                            <div class="request_demo_div">
                                <h4 id="product_name">{{product.name}}<a href="#" @click="editProduct()" v-if="rescheduleFlag" title="Change Product" > <i class="fa fa-pencil-alt" ></i></a></h4>
                                <p><small>Requested: {{productOb.first_name}}</small></p>
                                <p><small>Requested Slot: {{formatDate(productOb.local_time)}}</small></p>
                                <div class="form-row">
                                    <div class="form-group col-lg-2">
                                        <select class="form-select" name="title" id="title" required="" placeholder="Title" v-model="productOb.title">
                                            <option :value="title" v-for="title in titles" v-bind:key="title">{{ title }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <input type="text" class="form-control" placeholder="First Name" id="first_name" name="first_name" required="" v-model="productOb.first_name" />
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <input type="text" class="form-control" placeholder="Last Name" id="last_name" name="last_name" required="" v-model="productOb.last_name" />
                                    </div>

                                </div>
                                <div class="form-group">

                                </div>

                                <div class="form-group mt-3">
                                    <label for="inputAddress2">Add more people to your demo (Press enter after each email)</label>

                                    <div class='tag-input'>
                                        <div v-for='(tag, index) in tags' :key='tag' class='tag-input__tag'>
                                          <span @click='removeTag(index)'>x</span>
                                          {{ tag }}
                                        </div>
                                        <input type='text' v-model="emails"  placeholder="Enter all emails" class='tag-input__text' @keydown.enter='addTag' @keydown.188='addTag' @keydown.delete='removeLastTag' id="copy_people" name="copy_people" v-on:keyup="keymonitor"/>
                                      </div>
                                </div>
                                <div class="form-group">
                                    <label class="checkbox_div">Reschedule?
                                        <input type="checkbox" name="reschedule" value="true" id="reschedule" v-model="rescheduleFlag" @change="loadDates">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <p>
                                        <small id="reschedule_text">

                                        </small>
                                    </p>
                                </div>

                                <div class="form-group text-center mt-5">
                                    <button type="submit" class="btn main_btns" id="sub_bt" @click="updateSchedule()"  v-if="rescheduleFlag">Reschedule</button>
                                    <button type="submit" class="btn main_btns" id="sub_bt" @click="updateSchedule()" v-else>Add</button>
                                </div>
                                <hr/>
                                <p>Cancel Demo?</p>
                                <div class="form-group">
                                    <a href="#" class="btn btn-danger" @click="cancelDemo()">Cancel Demo Schedule</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
 </div>
 <div class="basic-loading-spinner" v-if="loadingSpinner === true">
    <!-- <div>{{ loadingPercentage }}</div> -->
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import DateInput from './DatePicker/components/Datepicker.vue';
var validator = require('validator');
const Swal = require('sweetalert2')
export default {
  name: "Products",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      titles : ["Dr.", "Mr.", "Ms.", "Mrs.","Miss"],
      countries : [],
      selected_dates : [],
      reserved_dates : [],
      time_slotes: [],
      selected_date : '',
      timezone : '',
      demo : {},
      product_name : '',
      product_id : '',
      timezone_name : '',
      timezoneWithHyphen : '',
      errors : [],
      firstModalFlag : false,
      secondModalFlag : false,
      product: '',
      products: [],
      productOb : {},
      rescheduleFlag : false,
      emails : '',
      tags: [],
      scheduledId : '',
      selected_time : '',
    };
  },
  components: {
    DateInput,
  },
  mounted() {
    this.secondModalFlag = true;
    var timezone_user = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (typeof timezone_user === "undefined") {
        alert('Please try a different browser!');
        return false;
    }
    this.timezoneWithHyphen = timezone_user.replace("/","---");
    this.timezone = timezone_user;
    this.timezone_name =  new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
  },
  created(){
    this.scheduledId = this.$route.params.id;
   this.getProducts();
  },
  computed: {
    loadingSpinner() {
      return this.$store.state.loadingSpinner;
    },
    dateSlotes(){
      return this.selected_dates.filter((f) => !this.reserved_dates.includes(f))
    },
    changeTimeSlotes : function(){
      if(this.time_slotes.length > 0){
          var filtered = [];
          var vm = this;
          this.time_slotes.forEach(function(item){
              if(vm.fotmatD(item.time_slot) == vm.selected_date){
                filtered.push(item)
              }
          });
          // this.selected_time = filtered.length > 0? filtered[0].id : '';
          return filtered;
      }
      return [];
    },
  },
  watch:{

  },
  methods: {
    fotmatD(d){
              var dat_val = moment(d);
              dat_val = dat_val.format('YYYY-MM-DD');
              return dat_val;
    },
    keymonitor: function(event) {
        if(event.key==","){
            this.addTag(event);
        }
    },
    next(){
        var vm = this;
         vm.modal_class = "";
         this.getProductData(this.product.id);
         vm.firstModalFlag = false,
         this.product_name = this.product.name;
         vm.secondModalFlag = true,
         vm.modal_class = "aos-init aos-animate";
    },
    timeChanged(){
        var optionValue = event.target.value;
        var optionText = event.target.options[event.target.options.selectedIndex].text;
        if(optionText.includes('Reserved')){
            this.selected_time = '';
        }else{
            if(this.selected_time!=''){
                this.errors['time_slot'] ='';
                return;
            }
        }
    },
    dateSelected : function(date){
      if(this.dateSlotes.includes(date)){
        this.selected_date = date;

      }
    },
    loadDates(){
        if(this.rescheduleFlag){
          this.$refs.rescheduledatePicker.$refs.dayPicker.loadDateList(this.productData);
        }
    },
    // login
    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        return;
      }
      this.modalFlag = val;
      var vm = this;
      setTimeout(function() {
        vm.modal_class = "aos-init aos-animate";
      }, 500);
    },
    closePopup(val){
        this.modal_class = "";
        // this.modalFlag = false;
        if(val == 1){
          this.firstModalFlag = false;

        } else {
        this.secondModalFlag = false;
        }
        this.$store.dispatch("setMenuClick", false);
    },
    getProductData(id){
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/scheduler/product/"+id+"/slots/"+this.timezoneWithHyphen)
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.productData    = response.data;
            this.selected_dates = response.data.dates;
            this.reserved_dates = response.data.reserved_dates;
            this.time_slotes    = response.data.time_slots;
            //
          }
        });
    },
    formatDate(date){
      var dat_val = moment(date);
            dat_val = dat_val.format('MM/DD/YYYY hh:mm A');
            return dat_val;
    },
    getSchedule(){
      if(this.scheduledId == ''){
          alert('Please provide a valid !');
          return;
      }
      this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/reschedule/"+this.scheduledId)
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.getProductData(response.data.slot.product_id);
            this.product_id = response.data.slot.product_id;
            this.selected_date = response.data.slot.date_slot;
            this.selected_time  = response.data.slot.time_slot;
            this.productOb  = response.data.slot;
            var copy_people = response.data.slot.copy_people;
            if(copy_people!=null){
                var copy_people_array = copy_people.split(",");

                for (let index in copy_people_array) {
                    this.tags.push(copy_people_array[index]);
                }
            }
            var vm = this;
            if(this.products.length > 0){
                vm.products.forEach(function(p) {
                  if(p.id == response.data.slot.product_id){
                    vm.product = p;
                  }

                });
            }

          }else{
            Swal.fire({
              title: "Error",
              html: response.data.message,
              type: "error",
              showConfirmButton: true,
              showDenyButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  window.location.replace(window.location.origin+'/schedule');
                  return true;
                } else if (result.isDenied) {
                  location.reload();
                  return false;
                }
            })
          }
        });
    },
    changeTimes(date){
            var dat_val = moment(date.time_slot);
            dat_val = dat_val.format('hh:mm A')+' '+this.timezone_name;
            if(date.is_reserved == "1"){
                dat_val+="(Reserved)";
            }
            // var dt = date.time_slot;
            // dt = dt.split(" ")[0];
            // var res = {id: date.id,time:dat_val};
            return dat_val
    },
    getProducts(){
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/scheduler/data")
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
                this.products = response.data.products;
                this.countries = response.data.countries;

                this.getSchedule();
          }
        });
    },
    editProduct : function(){
       var vm = this;
         vm.modal_class = "";
         vm.secondModalFlag = false,
         this.product_name = this.product.name;
         vm.firstModalFlag = true,
         vm.modal_class = "aos-init aos-animate";
    },
    // end of schedule
    cancelDemo (){
        this.$store.dispatch("setLoadingSpinner", true);
        this.secondModalFlag = false;
      axios
        .get(
          window.baseUrl+"/api/cancel/demo/"+this.scheduledId)
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
              Swal.fire({
                  title: "Success",
                  html: response.data.message,
                  type: "success",
                  showConfirmButton: true,
                  showDenyButton: false,
                  confirmButtonText: 'Ok',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      window.location.replace(window.location.origin+'/schedule');
                      return true;
                    } else if (result.isDenied) {
                      location.reload();
                      return false;
                    }
                })
          } else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.data.message
            })
          }
        });
    },
    addTag (event) {
          event.preventDefault()
          var val = event.target.value.trim();
          val = val.replace(/(^,)|(,$)/g, "");
          if (val.length > 0 ) {
            if(validator.isEmail(val)){
              this.tags.push(val)
              this.emails = ''
            }else{
                this.emails = '';
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Enter a valid email'
                })
            }

          }
    },
    removeTag (index) {
      this.tags.splice(index, 1)
    },
    removeLastTag(event) {
          if (event.target.value.length === 0) {
            this.removeTag(this.tags.length - 1)
          }
    },
    updateSchedule : function(){
      this.productOb.id = this.scheduledId;
      this.productOb.product_id = this.product_id;
      this.productOb.date_slot = this.selected_date;
      this.productOb.time_slot = this.selected_time;
      this.productOb.reschedule = this.rescheduleFlag ? 1:0;
      this.productOb.copy_people = this.tags.join();
      this.productOb.timezone_local = this.timezone;
      this.productOb.timezone_local_name = this.timezone_name;
      if(this.rescheduleFlag){
        this.productOb.product_id = this.product.id;
      }
      this.errors = [];
      if(!this.productOb.date_slot){
        this.errors['date_slot'] ='Please provide a date slot';
        return;
      }
      if(!this.productOb.time_slot){
        this.errors['time_slot'] ='Please provide a valid time slot';
        return;
      }

      if(!this.productOb.title){
        this.errors['title'] ='Please provide a title';
        return;
      }
      if(!this.productOb.first_name){
        this.errors['first_name'] ='Please provide a first name';
        return;
      }
      if(!this.productOb.last_name){
        this.errors['last_name'] ='Please provide a last name';
        return;
      }
      this.$store.dispatch("setLoadingSpinner", true);
      this.secondModalFlag = false;
       axios
        .post(
          window.baseUrl+"/api/scheduler/schedule/update",this.productOb)
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
            if (response.data.response == "success") {
                if (typeof response.data.reschedule !== 'undefined') {
                    Swal.fire({
                      title: "Success",
                      html: response.data.message,
                      type: "success",
                      showConfirmButton: true,
                      showDenyButton: true,
                      confirmButtonText: 'Yes',
                      denyButtonText: 'No',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          window.location.replace(window.location.origin+'/schedule');
                          return true;
                        } else if (result.isDenied) {
                          location.reload();
                          return false;
                        }
                    })

                }else{
                    this.secondModalFlag = true;
                    Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: response.data.message
                    })
                }

            }else{
                this.secondModalFlag = true;
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: response.data.message
                })
            }
        });

    },

  }
};
</script>

<style scoped>
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}

.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: #eee;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
  border-radius: 5px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
</style>
