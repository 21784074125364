<template>
    <header class="top_header w-100" v-if="0">
        <nav class="top_rig_menu">
            <ul class="text-center float-right">
                <li>
                    <a href="">
                        <img src="/images/logo.svg" class="img-fluid " alt="" />
                    </a>
                </li>
            </ul>
        </nav>
    </header>
      <div class="main-aria scheduler_content" v-if="modalFlag">
        <div class="container-fluid">
          <div class="row justify-content-center " >
            <div
              class="col-md-11 col-lg-9 col-xl-8 item  "
              :class="modal_class"
              data-aos="fade-up"
            >
              <div class="login_box p-5">
                 <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup()"><i class="far fa-times-circle"></i></a></div>
                    <div class="row row justify-content-center">
                        <div class="col-md-10 mb-4  text-center"><h4>&nbsp</h4></div>
                        <div class="col-md-6 text-center">
                            <img src="/images/logo.svg" class="img-fluid" alt="" />

                                <div class="form-group mt-5">
                                <select class="form-control sche_sel select_Product" id="date_slot" name="date_slot" required="" v-model="selected_date" @change="dateChanged()">
                                    <option :value="''">Select Date</option>
                                    <option :value="title" v-for="title in dateSlotes" v-bind:key="title">{{ title }}</option>
                                </select>
                                <small class="error" v-if="errors['date_slot']"> {{errors['date_slot']}}</small>
                                </div>
                                <div class="form-group my-2">
                                <select class="form-control sche_sel select_Product" id="time_slot" name="time_slot" required="" v-model="selected_time" @change="timeChanged($event)">
                                    <option value="">Select Time</option>
                                    <option :value="time.id" v-for="time in changeTimeSlotes" v-bind:key="time">{{ changeTimes(time)}}</option>

                                </select>
                                <small class="error" v-if="errors['time_slot']"> {{errors['time_slot']}}</small>
                                </div>
                                <div class="form-group my-2 text-right">
                                    <small id="timezone_user">
                                      {{timezone}}
                                    </small>
                                </div>
                                <div class="form-group mt-5">
                                    <date-input ref="datePicker"  @selectedagfaDate="dateSelected($event)" :value="selected_date"></date-input>
                                </div>

                        </div>
                        <div class="col-md-6">
                            <div class="request_demo_div">
                                <h2>Request a Demo</h2>
                                <h4 id="product_name">{{product_name}} <a href="#" @click="editProduct()" title="Change Product" > <i class="fa fa-pencil-alt" ></i></a></h4>
                                <p>Fill Details To Proceed</p>

                                <div class="form-row">
                                    <div class="form-group col-lg-2">

                                        <select class="form-select" name="title" id="title" placeholder="Title" v-model="demo.title">

                                            <option :value="title" v-for="title in titles" v-bind:key="title">{{ title }}</option>
                                        </select>
                                        <small class="error" v-if="errors['title']"> {{errors['title']}}</small>
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <input type="text" class="form-control" placeholder="First Name" id="first_name" name="first_name"  v-model="demo.first_name" />
                                        <small class="error" v-if="errors['first_name']"> {{errors['first_name']}}</small>
                                    </div>
                                    <div class="form-group col-lg-5">
                                        <input type="text" class="form-control" placeholder="Last Name" id="last_name" name="last_name" v-model="demo.last_name" />
                                        <small class="error" v-if="errors['last_name']"> {{errors['last_name']}}</small>

                                    </div>

                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Hospital or Facility" id="facilty" name="facilty" v-model="demo.facilty"/>
                                    <small class="error" v-if="errors['facilty']"> {{errors['facilty']}}</small>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="City" id="city" name="city" v-model="demo.city"/>
                                    <small class="error" v-if="errors['city']"> {{errors['city']}}</small>
                                    </div>
                                    <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="State/Province" id="state" name="state" v-model="demo.state"/>
                                    <small class="error" v-if="errors['state']"> {{errors['state']}}</small>
                                    </div>

                                </div>
                                <div class="form-row">
                                    <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="Zip/Postal Code" id="zip" name="zip" v-model="demo.zip" />
                                    <small class="error" v-if="errors['zip']"> {{errors['zip']}}</small>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <select class="form-select" name="country" id="country" required="" placeholder="Country/Providence" v-model="demo.country">
                                            <option value="">Select Country</option>
                                            <option :value="country.name" v-for="country in countries" v-bind:key="country.name">{{ country.name }}</option>
                                        </select>
                                        <small class="error" v-if="errors['country']"> {{errors['country']}}</small>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-lg-6">
                                    <input type="email" class="form-control" placeholder="Email" id="email" name="email" v-model="demo.email"/>
                                    <small class="error" v-if="errors['email']"> {{errors['email']}}</small>
                                    </div>
                                    <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="Phone" id="phone" name="phone"  v-model="demo.phone">
                                    <small class="error" v-if="errors['phone']"> {{errors['phone']}}</small>

                                    </div>
                                </div>
                                <div class="form-group mt-3">
                                    <label for="inputAddress2">Add more info</label>
                                    <textarea class="form-control" placeholder="Share what you're most interested in" rows="4" id="share_info" name="share_info" v-model="demo.share_info"></textarea>
                                    <small class="error" v-if="errors['share_info']"> {{errors['share_info']}}</small>
                                </div>
                                <div class="form-group text-center mt-5">
                                    <button type="submit" class="btn main_btns" id="sub_bt" @click="schedule()">Schedule</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
          </div>
        </div>
      </div>
  <!-- </div>
	</section> -->
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import DateInput from './DatePicker/components/Datepicker.vue';
var validator = require('validator');
const Swal = require('sweetalert2')
export default {
  name: "Products",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      titles : ["Dr.", "Mr.", "Ms.", "Mrs.","Miss"],
      countries : [],
      selected_dates : [],
      reserved_dates : [],
      time_slotes: [],
      selected_date : '',
      timezone : '',
      demo : {title:'Dr.'},
      product_name : '',
      product_id : '',
      timezone_name : '',
      timezoneWithHyphen : '',
      errors : [],
      selected_time : '',
    };
  },
  components: {
    DateInput
  },
  mounted() {

     var timezone_user = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (typeof timezone_user === "undefined") {
                alert('Please try a different browser!');
                return false;
            }
    this.timezoneWithHyphen = timezone_user.replace("/","---");
    this.timezone = timezone_user;
    this.timezone_name =  new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];


  },
  created(){
    // this.getProductData();
  },
  computed: {
    productDetailsFlag(){
      return this.$store.state.productContentFlagLoaded;
    },
    dateSlotes(){
      // console.log('selected dates', this.selected_dates, 'reserved dates', this.reserved_dates)
      var dates =  this.selected_dates.filter((f) => !this.reserved_dates.includes(f))
      this.selected_date  = dates.length > 0 ? dates[0] : '';
      return dates;

    },
    changeTimeSlotes : function(){
      if(this.time_slotes.length > 0){
          var filtered = [];
          var vm = this;
          this.time_slotes.forEach(function(item){
              if(vm.fotmatD(item.time_slot) == vm.selected_date){
                filtered.push(item)
              }
          });
          // this.selected_time = filtered.length > 0? filtered[0].id : '';
          return filtered;
      }
      return [];
    },

  },
  methods: {

    dateChanged(){
      // this.$refs.datePicker.dayPicker.
    },
    timeChanged(){
        var optionValue = event.target.value;
        var optionText = event.target.options[event.target.options.selectedIndex].text;
        if(optionText.includes('Reserved')){
            this.selected_time = '';
        }else{
            if(this.selected_time!=''){
                this.errors['time_slot'] ='';
                return;
            }
        }
    },
    removeValidation(field){
        if(this.field!=''){
            this.errors[field] ='';
            return;
        }
    },

    fotmatD(d){
              var dat_val = moment(d);
              dat_val = dat_val.format('YYYY-MM-DD');
              return dat_val;
    },

    dateSelected : function(date){
      if(this.dateSlotes.includes(date)){
        this.selected_date = date;

      }
    },
    // login
    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        this.modalFlag = val;
        this.$store.dispatch("setMenuFlagLoaded", false);
        return;
      }
      this.modalFlag = val;
      this.$store.dispatch("setMenuFlagLoaded", true);
      var vm = this;
      setTimeout(function() {
        vm.modal_class = "aos-init aos-animate";
      }, 500);
    },
    getProductData(id){
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/scheduler/product/"+id+"/slots/"+this.timezoneWithHyphen)
        .then(response => {
          this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.productData    = response.data;
            this.selected_dates = response.data.dates;
            this.reserved_dates = response.data.reserved_dates;
            this.time_slotes    = response.data.time_slots;
            this.$refs.datePicker.$refs.dayPicker.loadDateList(this.productData);
          }
        });
    },
    changeTimes(date){


            var dat_val = moment(date.time_slot);
            dat_val = dat_val.format('hh:mm A')+' '+this.timezone_name;
            if(date.is_reserved == "1"){
                dat_val+="(Reserved)";
            }
            // var dt = date.time_slot;
            // dt = dt.split(" ")[0];
            // var res = {id: date.id,time:dat_val};
            return dat_val
    },
    schedule : function(){


      this.demo.product_id = this.product_id;
      this.demo.date_slot = this.selected_date;
      this.demo.time_slot = this.selected_time;
      this.demo.timezone_local = this.timezone;
      this.demo.timezone_local_name = this.timezone_name;

      if(this.validateAll('all')){
        return false;
      }


      this.$store.dispatch("setLoadingSpinner", true);
      var vm = this;
      axios
        .post(
          window.baseUrl+"/api/scheduler/schedule",this.demo)
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
            if (response.data.response == "success") {
                  Swal.fire({
                  title: "Success",
                  html: response.data.message,
                  type: "success",
                  showConfirmButton: true,
                  showDenyButton: true,
                  confirmButtonText: 'Yes',
                  denyButtonText: 'No',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  window.location.replace(window.location.origin+'/schedule');
                  return true;
                } else if (result.isDenied) {
                  window.location.replace(window.location.origin+'/'+response.data.reschedule);
                  return false;
                }
              })
            }else{
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: response.data.message
                })
            }
        });

    },
    closePopup(){
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setMenuFlagLoaded", false);
        this.$store.dispatch("setMenuClick", false);
    },
    editProduct(){
            var vm = this;
            vm.showPopup(false)
              vm.modalFlag = false;
              vm.$parent.showPopup(true);
    },
    validateAll(type){
        var error=false;
        var errors = [];
        errors['date_slot'] ='Please provide a date slot';
        errors['time_slot'] ='Please provide a valid time slot';
        errors['first_name'] ='Please provide a first name';
        errors['last_name'] ='Please provide a last name';
        errors['facilty'] ='Please provide a facilty';
        errors['city'] ='Please provide a city';
        errors['state'] ='Please provide a state';
        errors['country'] ='Please provide a country';
        errors['zip'] ='Please provide a zip';
        errors['email'] ='Please provide a valid email';


        if(type!='all'){
            if(type=='zip'){
                if(this.demo.country =='UNITED STATES'){
                    if(!this.demo.zip){
                      this.errors[type] = errors[type];
                      error=true;
                    }else{
                        this.errors[type] = '';
                    }
                }else{
                    this.errors[type] = '';
                }
            }else if (type=='email'){
                if(!this.demo.email || ! validator.isEmail(this.demo.email)){
                    this.errors[type] = errors[type];
                    error=true;
                }else{
                    this.errors[type] = '';
                }
            }else if(!this.demo[type]){
                this.errors[type] = errors[type];
                error=true;
            }else{
                this.errors[type] = '';
            }
            return error;
        }

        this.errors = [];
        for (let index in errors) {
            if(index=='zip'){
                if(this.demo.country =='UNITED STATES'){
                    if(!this.demo.zip){
                      this.errors[index] = errors[index];
                      error=true;
                    }
                }
            }else if (index=='email'){
                if(!this.demo.email || ! validator.isEmail(this.demo.email)){
                    this.errors[index] = errors[index];
                    error=true;
                }
            }else if(!this.demo[index]){
                this.errors[index] = errors[index];
                error=true;
            }
        }
        return error;
    }
  },
  watch:{
    productDetailsFlag(val){
      if(val == true){
        this.closePopup()
      }
    },
    'demo.first_name'(value){
        this.validateAll('first_name');
    },
    'demo.email'(value){
        this.validateAll('email');
    },
    'demo.last_name'(value){
        this.validateAll('last_name');
    },
    'demo.facilty'(value){
        this.validateAll('facilty');
    },
    'demo.city'(value){
        this.validateAll('city');
    },
    'demo.state'(state){
        this.validateAll('state');
    },
    'demo.country'(value){
        this.validateAll('country');
    },
    'demo.zip'(value){
        this.validateAll('zip');
    },
  }
};
</script>

