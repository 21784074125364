import * as THREE from "three";
// import Stats from "three/examples/jsm/libs/stats.module";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import CameraControls from "camera-controls";
import store from "../store";

const TWEEN = require("@tweenjs/tween.js");

CameraControls.install({ THREE: THREE });

class ThreeMain {
  constructor() {
    this.container = document.getElementById("threejs-container");
    this.clock = new THREE.Clock();

    this.width = window.innerWidth + 200;
    this.height = window.innerHeight;

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      55,
      this.width / this.height,
      0.1,
      1000
    );
    // this.camera.position.set(0, 0, 7);
    this.camera.position.set(0, 0, 0.01);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 0.74;
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, window.innerHeight);
    this.container.appendChild(this.renderer.domElement);
    // this.renderer.render(this.scene, this.camera);

    window.addEventListener("resize", this.onWindowResize.bind(this), false);
    this.cameraControls = new CameraControls(
      this.camera,
      this.renderer.domElement
    );
    // this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.NONE;

    this.cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    this.cameraControls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
    this.cameraControls.touches.three = CameraControls.ACTION.TOUCH_TRUCK;

    // this.stats = new Stats();
    // this.container.appendChild(this.stats.dom);
    this.loaderTexture = new THREE.TextureLoader();
    this.gltfLoader = new GLTFLoader();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/"
    );
    this.gltfLoader.setDRACOLoader(dracoLoader);

    this.group = new THREE.Group();

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.animationPlay = false;
    this.mixer = undefined;
    this.animationClips = undefined;
    this.isAnnotations = false;
    this.modalShow = false;
  }

  onWindowResize() {
    this.width = (window.innerWidth + 200);
    this.height = window.innerHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.width, this.height);
  }

  setModalShow=(val)=>{
    this.modalShow = val;
    if (val === true) 
      document.getElementById("threejs-container").classList.add("push");
    else document.getElementById("threejs-container").classList.remove("push");
    let hotspotArr = this.scene.getObjectByName("hotspots");
    let annotationArr = this.scene.getObjectByName("annotations");
    let infoArr = this.scene.getObjectByName("info");

    [hotspotArr, annotationArr, infoArr].forEach(arr => {
      if (!arr) return;
      arr.visible = !val;
    });
  }

  animate(timestamp) {
    // this.stats.begin();
    requestAnimationFrame(this.animate.bind(this));
    const delta = this.clock.getDelta();
    this.cameraControls.update(delta);
    TWEEN.update(timestamp);
    this.animationPlay && this.mixer.update(delta);
    this.renderer.render(this.scene, this.camera);
    const {modalShow} = store.state;
    if (store.state.modalShow !== this.modalShow) {
      this.setModalShow(modalShow);
    }
    // this.stats.end();
  }
}

export default ThreeMain;
