<template>
     <header class="top_header w-100" v-if="modalFlag">
        <nav class="top_rig_menu">
            <ul class="text-center float-right">
                <li>
                    <a href="https://medimg.agfa.com/main/direct-radiography">
                        <img src="/images/logo.svg" class="img-fluid " alt="" />
                    </a>
                </li>
            </ul>
        </nav>
    </header>
    <div class="main-aria scheduler_content" v-if="modalFlag">
        <div class="container-fluid" v-if="!loadingSpinner">
            <div class="row justify-content-center " >
                <div
                  class="col-md-11 col-lg-9 col-xl-6 item  "
                  :class="modal_class"
                  data-aos="fade-up"
                >
                  <div class="login_box p-5 text-center">
                     <div class="row row justify-content-center">
                        <div class="col-md-12 text-center">
                             <div class="base-timer" v-if="runTimer">
                                <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                  <g class="base-timer__circle">
                                    <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                                    <path
                                      :stroke-dasharray="circleDasharray"
                                      class="base-timer__path-remaining"
                                      :class="remainingPathColor"
                                      d="
                                        M 50, 50
                                        m -45, 0
                                        a 45,45 0 1,0 90,0
                                        a 45,45 0 1,0 -90,0
                                      "
                                    ></path>
                                  </g>
                                </svg>
                                <span class="base-timer__label">{{ formattedTimeLeft }}</span>
                                <p>Please wait...</p>
                              </div>

                              <div class="form-group" v-if="runTimerExpired">
                                <p>This meeting has been exprired!</p>
                                <button
                                  type="button"
                                  class="btn login_btn continue_btn"
                                  @click="toHome()"
                                >
                                  Continue
                                </button>
                              </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="basic-loading-spinner" v-if="loadingSpinner === true">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;

const COLOR_CODES = {
  info: {
    color: "green"
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD
  }
};

export default {
  name: "Products",
  data() {
    return {
      modalFlag: false,
      runTimer: false,
      runTimerExpired: false,
      modal_class: "",
      product : {},
      timeLimit: 0,
      timePassed: 0,
      scheduledId: 0,
      timerInterval: null,
      demoUrl: '',
    };
  },
  components: {
  },
  mounted() {
    this.startTimer();
    console.log(moment().format());
  },
  created(){
    this.scheduledId = this.$route.params.id;
    this.getScheduledInfo();

  },
  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if(timeLeft > 3600 && minutes>59){
            var dayTxt = this.secondsToDhms(timeLeft)
            return dayTxt.substr(0, dayTxt.indexOf(','));
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timeLimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
    loadingSpinner() {
      return this.$store.state.loadingSpinner;
    },
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    }
  },
  methods: {
    // login

    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        return;
      }
      this.modalFlag = val;
      var vm = this;
      setTimeout(function() {
        vm.modal_class = "aos-init aos-animate";
      }, 500);
    },
    getScheduledInfo(){
        this.$store.dispatch("setLoadingSpinner", true);
      axios
        .get(
          window.baseUrl+"/api/scheduled/info/"+this.scheduledId)
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
          if (response.data.response == "success") {
            this.demoUrl = response.data.products.demo_link;
            if(response.data.products.demo_link == ''){
                this.demoUrl = window.location.origin;
            }

            var now = moment(new Date()); //todays date
            var end_to_current_tm = moment.utc(response.data.slot.utc_time).local().format('YYYY-MM-DD HH:mm:ss');
            var end = moment(end_to_current_tm); // another date


            var currnet_diff = end.diff(now, 'minutes');
            var slot_min = Number(response.data.time_slot.slot_type);

            if(currnet_diff < 0){
                var currnet_diff_slot =  slot_min + currnet_diff;
                if(currnet_diff_slot < 0){
                    window.location.href = this.demoUrl;
                }else{
                    this.runTimer = true;
                    this.timeLimit = 1;

                    window.location.href = this.demoUrl;
                }
            }else{
                this.runTimer = true;
                this.timeLimit = currnet_diff*60;
            }
          }else{
               this.runTimerExpired = true;
          }

        });
    },
    onTimesUp() {
        window.location.href = this.demoUrl;
        clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    toHome() {
        window.location.href = window.location.origin;
    },
    secondsToDhms(seconds) {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    },
  }
};
</script>

<style lang="scss">
.base-timer {
  position: relative;
  margin: auto;
  width: 300px;
  height: 300px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
