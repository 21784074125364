<template>
  <nav class="radialnav" v-if="menuLoaded&&!modalLoaded" :class="clicked ? 'active' : ''">
    <a href="#" class="ellipsis" @click="menuClicked()">
      <img src="/images/c_menu_icon.svg" alt="" class="c_menu_icon fas fa-th" />
      <img src="/images/c_close.svg" alt="" class="c_close fas fa-times" />
    </a>
    <ul class="menu" :class="disabledbutton?'disabledbutton':''">
      <li class="sound_on_box sound_on_icon" id="hideshow">
        <a href="#" @click="loadUrl('https://medimg.agfa.com/main/musicaplay/')"
          ><img src="/images/icons/atrium_icon.svg" alt="" /><span
            >Compare Images <br />MUSICA Play</span
          ></a
        >
      </li>
      <li class="sound_on_box live_chat_box">
        <a href="#" @click="menuSelected('ScheduleStepOne')"
          ><img src="/images/icons/schedule_icon.svg" alt="" /><span
            >Schedule<br />a Demo</span
          ></a
        >
      </li>
      <li class="sound_on_box expiore_box">
        <a href="#" @click="menuSelected('products')"
          ><img src="/images/icons/touch_icon.svg" alt="" /><span
            >Products</span
          ></a
        >
      </li>
      <!-- Event Active -->
      <!-- <li class="sound_on_box info_box">
        <a href="#" @click="menuSelected('eventList')">
          <img src="/images/icons/event_icon.svg" alt="" />
          <span>Events</span>
        </a>
      </li> -->
      <!-- Events Disabled -->
      <li class="sound_on_box info_box">
        <a href="#" title="No Events Availble">
          <img src="/images/icons/event_icon--disabled.png" alt=""  />
          <span v-bind:style="{ 'color': '#888' }">Events</span>
        </a>
      </li>
      <li class="sound_on_box demand_content">
        <a href="#" @click="menuSelected('onDemand')"
          ><img src="/images/icons/slow_motion_icon.svg" alt="" /><span
            >On Demand <br />Content</span
          ></a
        >
      </li>
      <li class="sound_on_box home_icon_box" @click="loadHome()">
        <a href="#">
            <img src="/images/icons/atrium_icon.svg" alt="" />
            <span>Home</span>
        </a>
      </li>
      <!-- <li class="sound_on_box home_icon_box" @click="muteVideo()">
        <a href="#"
          ><img src="/images/icons/mute_icon.svg" alt="" /><span v-if="isVideoMuted">Unmute</span><span v-else>Mute</span></a
        >
      </li> -->
    </ul>
  </nav>
    <header class="top_header w-100" >
        <nav class="top_rig_menu">
            <ul class="text-center float-right">
                <li>
                    <a href="https://medimg.agfa.com/main/direct-radiography">
                        <img :src="header_logo" class="img-fluid " alt="" />
                    </a>
                    <p v-if="isGallery">DR Room</p>
                    <p v-if="isDrRoom">Mobile DR</p>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      reg: "",
      clicked: false,
      modalLoaded: false,
      disabledbutton: false,
      previous_menu: "",
      header_logo: "/images/logo.svg"
    };
  },
  mounted() {},
  computed: {
    menuLoaded() {
      return this.$store.state.menuFlag;
    },
    isCubeMap(){
      return this.$store.state.isCubeMap;
    },
    isGallery() {
      return this.$store.state.isGallery;
    },
    isDrRoom() {
      return this.$store.state.isDrRoom;
    },
    isVideoMuted() {
      return this.$store.state.muteVideo;
    },
    isModalLoaded() {
      return this.$store.state.menuClick;
    },
  },
  watch: {
    isModalLoaded(val){
        var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if(width<1200){
            if(val){
                this.modalLoaded = true;
            }else{
                this.modalLoaded = false;
            }
        }
    }
  }
  ,
  methods: {
    menuClickedVideo() {
      this.clicked = true;
    },
    menuClicked() {
      this.clicked = !this.clicked;
    },
    menuSelected(val) {
      this.$store.dispatch("setMenuClick", true);
      var defaultIndex = 0;
      if(val.includes("--")){
            var menuNaming = val.split("--");
            defaultIndex = menuNaming[0];
            val = 'onDemand';
      }


      if (this.previous_menu != "") {
            if(this.previous_menu == 'onDemand'){
                this.$parent.$refs[this.previous_menu].showPopup(false,defaultIndex);
            }else{
                this.$parent.$refs[this.previous_menu].showPopup(false);
                if(this.previous_menu == 'ScheduleStepOne'){
                    this.$parent.$refs['ScheduleStepOne'].closeSecondPopUp();
                }
            }

      }
      if(val!='onDemand'){
        this.$parent.$refs['onDemand'].stopVideo();
        this.$parent.$refs[val].showPopup(true);
      }else{
        this.$parent.$refs[val].showPopup(true,defaultIndex);
      }

      this.previous_menu = val;
      this.$parent.$refs['introProductVideo'].stopVideo();
      this.disabledbutton = true;
      setTimeout(() => this.disabledbutton = false, 3000);
    },
    muteVideo: function() {
        //this.$parent.$refs['introProductVideo'].muteVideo();
        if(this.isVideoMuted){
            this.$store.dispatch("setMuteVideo", false);
        }else{
            this.$store.dispatch("setMuteVideo", true);
        }
    },
    loadUrl: function(url) {
        window.open(url, '_blank');
    },
    loadHome: function() {
        if (this.previous_menu != "") {
            if(this.previous_menu == 'onDemand'){
                this.$parent.$refs[this.previous_menu].showPopup(false,defaultIndex);
            }else{
                this.$parent.$refs[this.previous_menu].showPopup(false);
                if(this.previous_menu == 'ScheduleStepOne'){
                    this.$parent.$refs['ScheduleStepOne'].closeSecondPopUp();
                }
            }

        }
        this.$parent.$refs['introProductVideo'].stopVideo();
        this.disabledbutton = true;
        setTimeout(() => this.disabledbutton = false, 3000);
        this.$parent.loadCubeMapFromHome();

    }
  }
};
</script>

<style>
    .disabledbutton {
        pointer-events: none;
        opacity: 0.4;
    }
    .disabledbutton li {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
