<template>

      <div class="main-aria"  v-if="modalFlag">
        <div class="container-fluid">
          <div class="row justify-content-center ">
            <div
              class="col-md-9 col-lg-9 col-xl-8 item  "
              :class="modal_class"
              data-aos="fade-up"
            >
              <div class="login_box p-3">
                <div class="row">
                  <div class="col-lg-8 d_order_2">
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div class="product_video_height">
                            <iframe :src='videoId' :width='YWIDTH' :height='YHEIGHT' frameborder='0' allow='autoplay; fullscreen' allowfullscreen v-if="vimeo_show">

                            </iframe>
                            <iframe :width='YWIDTH' :height='YHEIGHT' :src="videoId" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-if="youtube_show"></iframe>
                            <img
                              :src="img_src"
                              alt=""
                              class="w-100 demand_content_img"
                              v-if="image_show"
                              :player-parameters="playerParams"
                            />

                        </div>
                        <div class="row" v-if="one_hundred_e">
                            <div class="col-sm-12 text-center">
                                DR 100e is not currently available in the US and Canada.
                            </div>
                        </div>
                        <div class="row odc_height">
                          <div class="col-sm-6 text-center">
                            <a href="#" class="demand_btns mt-3" @click="loadVideo(short_url);" v-if="short_url!=null">Watch Short Video</a>
                            <a href="#" class="demand_btns mt-3" @click="loadVideo(long_url);" v-if="musica_play&&long_url!=null">Visit MUSICA Play</a>
                            <a href="#" class="demand_btns mt-3" @click="loadVideo(long_url);" v-if="!musica_play&&long_url!=null">Watch Long Video</a>
                          </div>
                          <div class="col-sm-6 text-center mt-3">
                            <a href="#" @click="downloadPdf()" class="demand_btns download_collateral_btn"
                              >View/Download Collateral <i class="fas fa-download"></i
                            ></a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-4 d_order_1">
                    <div class="close_btn_odc"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup()"><i class="far fa-times-circle"></i></a></div>
                    <nav>
                      <div class="request_demo_div select_product_div">
                        <h3>Select a Product</h3>
                        <div
                          class="nav nav-tabs select_product_inner odc"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a class="nav-item nav-link" :class="odcontent.active"
                            id="nav-home-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            v-for="(odcontent,index) in demand_contents"
                            v-bind:key="odcontent.id"
                            @click="loadContent(index,odcontent);"
                            >{{ odcontent.title }}</a>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OnDemandContent",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      musica_play: false,
      one_hundred_e: false,
      musica: "#",
      demand_contents: [],
      image_show: false,
      vimeo_show: false,
      youtube_show: false,
      videoId : false,
      YWIDTH : '100%',
      YHEIGHT : '500px',
      img_src: "images/demand_content_img1.jpg",
      pdf_url: "",
      file_name: "",
      short_url: "",
      long_url: "",
      current_index: "",
    };
  },
  components: {
  },
  mounted() {},
  computed: {
    productDetailsFlag(){
      return this.$store.state.productContentFlagLoaded;
    }
  },
  methods: {
    // login
    showPopup: function(val,defaultIndex) {
      if (!val) {
        this.modal_class = "";
        this.modalFlag = val;
        this.$store.dispatch("setMenuFlagLoaded", false);
        return;
      }
      this.$store.dispatch("setLoadingSpinner", true);
      axios
        .post(
          window.baseUrl+"/api/contents/get",
          {
            user_id: this.$store.state.auth_user.id
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {
            this.$store.dispatch("setLoadingSpinner", false);
            if (response.data.response == "success") {
                this.demand_contents = response.data.contents;
                if(response.data.content_count){
                    var main_content = this.demand_contents[defaultIndex];
                    this.loadContent(defaultIndex,main_content);
                }

                this.modalFlag = val;
                this.$store.dispatch("setMenuFlagLoaded", true);
                var vm = this;
                setTimeout(function() {
                  vm.modal_class = "aos-init aos-animate";
                }, 500);
            }
        });
    },
    loadContent: function(index,odcontent) {

        if(this.current_index!=""){
            this.demand_contents[this.current_index].active = '';
        }
        if(index==0){
            index = "0";
        }
        this.postAnalytics(odcontent.id,0);
        this.current_index = index;
        this.demand_contents[index].active = 'active';
        this.image_show = false;
        this.youtube_show = false;
        this.vimeo_show = false;
        this.img_src = odcontent.image;
        this.pdf_url = window.baseUrl+"/storage/"+odcontent.pdf;
        this.file_name = odcontent.title;
        this.short_url = odcontent.short_video;
        this.long_url = odcontent.long_video;
        if(odcontent.short_video==null){
            if(odcontent.image==null){
                if(odcontent.long_video==null){
                    this.img_src = "images/demand_content_img.png";
                    this.image_show = true;
                }else{
                    this.loadVideo(odcontent.long_video);
                }
            }else{
                this.img_src = window.baseUrl+"/storage/"+this.img_src;
                this.image_show = true;
            }
        }else{
            this.loadVideo(odcontent.short_video);
        }
        if(odcontent.title.includes("MUSICA")){
            this.musica_play = true;
            this.musica = this.long_url;
        }else{
            this.musica_play = false;
            this.long_video_text = "Watch Long Video";
        }
        this.one_hundred_e = false;
        if(odcontent.title.includes("100e")){
            this.one_hundred_e = true;
        }

    },
    loadVideo: function(url) {
        if(url==null){
            return 0;
        }
        if(url.includes("vimeo")){
            this.image_show = false;
            this.youtube_show = false;
            this.vimeo_show = true;
            this.videoId = url;
        }else if(url.includes("youtube")){
            this.image_show = false;
            this.vimeo_show = false;
            this.videoId = this.youtubeParser(url);
            this.youtube_show = true;
        }else{
            window.open(url, '_blank');
        }
    },
    youtubeParser: function(url){
          var ID = '';
          // eslint-disable-next-line
          url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
          if(url[2] !== undefined) {
            // eslint-disable-next-line
            ID = url[2].split(/[^0-9a-z_\-]/i);
            ID = ID[0];
            var host = encodeURI(location.host);

            ID = 'https://www.youtube.com/embed/'+ID+'?enablejsapi=1&origin='+host+'&widgetid=1';
          }
          else {
            ID = url;
          }
            return ID;
    },
    stopVideo: function() {
        this.youtube_show = false;
        this.vimeo_show = false;
    },
    downloadPdf(){
        window.open(this.pdf_url, '_blank');
    },
    closePopup(){
        this.postAnalytics(0,1);
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setMenuFlagLoaded", false);
        this.$store.dispatch("setMenuClick", false);
    },
    postAnalytics(product_id,stop){
        if(product_id==0&&stop==0){
            return false;
        }
        axios
        .post(
          window.baseUrl+"/api/analytics/post",
          {
            user_id: this.$store.state.auth_user.id,
            product_id: product_id,
            page: 'ondemand',
            feature_id: 0,
            stop: stop
          },
          {
            headers: {
              accesstoken: this.$store.state.auth_user.api_token
            }
          }
        )
        .then(response => {

        });
    },
  },
  watch:{
    productDetailsFlag(val){
      if(val == true){
        this.closePopup()
      }
    }
  }
};
</script>

