<template>
 
				<ScheduleStepOne ref="ScheduleStepOne" />
	
	<div class="bg_div" style="background: #000;"><img src="/images/bg.jpg" alt="" class="w-100 bg_img" /></div>
</template>
<script>
import ScheduleStepOne from "../components/schedule/ChooseProductComponent.vue";
export default {
name: "Schedule",
  components: {
    ScheduleStepOne,
  },
  mounted(){
    this.$refs.ScheduleStepOne.showPopup(true);
  },
  beforeUnmount() {
    this.$store.dispatch("setSceneLoaded", false)
  }
}
</script>

<style>

</style>