<template>
    <JoinMeetingCounter ref="JoinMeetingCounter" />
	<div class="bg_div" style="background: #000;"><img src="/images/bg.jpg" alt="" class="w-100 bg_img" /></div>
</template>
<script>
import JoinMeetingCounter from "../components/schedule/JoinMeetingCounter.vue";
export default {
name: "JoinMeeting",
  components: {
    JoinMeetingCounter,
  },
  mounted(){
    this.$refs.JoinMeetingCounter.showPopup(true);
  },
  beforeUnmount() {
    this.$store.dispatch("setSceneLoaded", false)
  }
}
</script>

<style>

</style>
