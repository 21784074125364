var host_url = window.location.origin;
module.exports = {
    "localhost": {
        "url" :"http://agfa.thetunagroup.com"
    },
    "production": {
        "url" :"https://admin.compare-agfa-radiology.com"
    },
    "host_url": {
        "url" :host_url
    },
    "env": "production"
}
