<template>
  <div
    class="page_loader"
    v-if="agfaLoading"
  >
    <div class="page_loader_inner">
      <div class="progress md-progress" style="height: 15px">
        <div
          class="progress-bar"
          role="progressbar"
          style="height: 15px"
          aria-valuenow="56"
          aria-valuemin="0"
          aria-valuemax="100"
          v-bind:style="{ width: loadingPercent+'%' }"
        >
          <span>{{ loadingPercent }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AgfaMainLoader",
    created() {
    },
    methods: {
        doProgress() {
            let step = this.loadTime / 100;
                this.interval = setInterval(() => {
                this.loadingPercent++;
            }, step);
        },
        showLoader() {
            this.loadingPercent = 0;
            let perfData = window.performance.timing;
            let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart);
            this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
            this.doProgress();
            this.agfaLoading = true;
        },
        hideLoader() {
            this.agfaLoading = false;
        },
    },
    computed: {
      loaded() {
        return this.loadingPercent;
      }
    },
    watch: {
      loadingPercent(val) {
        if (val >= 100) {
            this.loadingPercent = 100;
            //this.agfaLoading = false;
            clearInterval(this.interval)
        }
      }
    },
    data() {
      return {
        agfaLoading: false,
        loadingPercent: 0,
        loadTime: 0,
        interval: null
      }
    }
};
</script>
