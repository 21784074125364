const cubeMapData = {
  sceneId: "b5291194-f0dd-42c7-bcb5-af37e2f6f8e9",
  rotation: {
    x: 0,
    z: 0,
    y: 0,
  },
  distance: {
    start: 2,
    min: 1.3,
    max: 2,
  },
  azimuth: {
    // start: 0.04027315568707374,
    start: 0,
    min: -0.4,
    max: 1,
  },
  // polar: { min: Math.PI / 1.9, max: Math.PI / 2.1 },
  polar: {
    min: 1.04,
    max: 1.911060527670575,
    start: 1.5707963267948966,
  },
  icons: [
    {
      id: 1, //DR Room
      pos: {
        x: -22.566806354932574,
        y: 1.651629964577451,
        z: -40.2164790482223,
      },
      rot: {
        z: -0.6,
        y: 0,
        x: Math.PI / 2,
      },
      scale: {
        x: 2.5,
        y: 0.7,
        z: 1,
      },
    },
    // {
    //   id: 3, //event
    //   pos: {
    //     x: 11.012607529718478,
    //     y: 4.2969759898209166,
    //     z: -40.78811033752436,
    //   },
    //   rot: {
    //     z: 0,
    //     y: 0,
    //     x: Math.PI / 2,
    //   },
    //   scale: {
    //     x: 1,
    //     y: 0.4,
    //     z: 0.5,
    //   },
    // },
    {
      id: 2, //Schedule-Demo
      rot: {
        z: 0.6,
        y: 0,
        x: Math.PI / 2,
      },
      pos: {
        x: 25.088960182418,
        y: 1.746824534927653,
        z: -40.57653583031595,
      },
      scale: {
        x: 1.4,
        y: 0.5,
        z: 0.5,
      },
    },
  ],
  hotspots: [
    {
      id: 1, //DR Room
      arrowposs: {
        x: -30.144126781930815,
        y: -0.30397287722953337,
        z: -46.2164790482223,
      },
      rotation: {
        z: 0,
        y: 0.663225414957994,
        x: 0,
      },
      scale: {
        x: 2.5,
        y: 0.7,
        z: 1,
      },
    },
    // {
    //   id: 3, //event
    //   arrowposs: {
    //     x: 8.410300028570514,
    //     y: 0.629174797291344,
    //     z: -47.25345734636069,
    //   },
    //   rotation: {
    //     z: 0,
    //     y: 0,
    //     x: 0,
    //   },
    //   scale: {
    //     x: 2.2,
    //     y: 0.7,
    //     z: 0.5,
    //   },
    // },
    {
      id: 2, //Schedule-Demo
      rotation: {
        z: 0,
        y: 0,
        x: 0,
      },
      arrowposs: {
        x: 25.5224964682656,
        y: -2.6221009307844945,
        z: -45.57653583031595,
      },
      scale: {
        x: 2.2,
        y: 0.7,
        z: 0.5,
      },
    },
  ],
  position: {
    z: 0,
    x: 0,
    y: 0,
  },
  urls: {
    px: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/px.jpg",
    nx: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/nx.jpg",
    py: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/py.jpg",
    ny: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/ny.jpg",
    pz: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/pz.jpg",
    // nz: "https://s3.amazonaws.com/agfa.assets/atrium-cubemap-v4/nz.jpg",

    // Added new background image for this section of the cubemap. This image has a generic poster in the background instead of the events poster
    nz: "/images/cubemap/main-lobby/nz.jpg",
  },
};

export default cubeMapData;
