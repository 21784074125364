import { createStore } from "vuex";

export default createStore({
  state: {
    email: null,
    currentState: "home",
    sceneLoaded: false,
    initThreeProgress: 0,
    menuFlag: false,
    isCubeMap: false,
    isGallery: false,
    isDrRoom: false,
    loadingSpinner: false,
    loadingPercentage: 0,
    annotationActiveId: false,
    activeModel: {},
    auth_user: {},
    productContentLoaded: true,
    introVideoStarted: false,
    muteVideo: false,
    productContentFlagLoaded: false,
    menuFlagLoaded: false,
    animationPlay: false,
    menuClick: false,
    infoData: {},
    activeAtriumBannerId: undefined,
    modalShow:false
  },

  mutations: {
    SET_EMAIL(state, email) {
      state.email = email;
    },
    CURRENT_STATE(state, currentState) {
      state.currentState = currentState;
    },
    SCENE_LOADED(state, sceneLoaded) {
      //console.log("scene state", sceneLoaded);
      state.sceneLoaded = sceneLoaded;
    },
    SET_INIT_THREE_PROGRESS(state, initThreeProgress) {
      state.initThreeProgress = initThreeProgress;
    },
    MENU_FLAG(state, menuFlag) {
      state.menuFlag = menuFlag;
    },
    SET_CURRENT_ROOM(state, { isCubeMap, isGallery, isDrRoom }) {
      state.isCubeMap = isCubeMap;
      state.isGallery = isGallery;
      state.isDrRoom = isDrRoom;
    },
    SET_ACTIVE_MODEL(state, data) {
      state.activeModel = data;
    },
    SET_AUTH_USER(state, data) {
      state.auth_user = data;
    },
    SET_LOADING_SPINNER(state, data) {
      state.loadingSpinner = data;
    },
    SET_LOADING_PERCENTAGE(state, data) {
      state.loadingPercentage = data;
    },
    SET_ANNOTATION_ACTIVE_ID(state, data) {
      state.annotationActiveId = data;
    },
    SET_PRODUCT_CONTENT_LOADED(state, data) {
      state.productContentLoaded = data;
    },
    SET_INTRO_VIDEO_STARTED(state, data) {
      state.introVideoStarted = data;
    },
    SET_PRODUCT_CONTENT_FLAG_LOADED(state, data) {
      state.productContentFlagLoaded = data;
    },
    SET_MENU_FLAG_LOADED(state, data) {
      state.menuFlagLoaded = data;
    },
    SET_ANIMATION_PLAY(state, data) {
      state.animationPlay = data;
    },
    SET_INFO_DATA(state, data) {
      state.infoData = data;
    },
    SET_MUTE_VIDEO(state, data) {
      state.muteVideo = data;
    },
    SET_MENU_CLICK(state, data) {
      state.menuClick = data;
    },
    SET_ATRIUM_BANNER_CLICK(state, data) {
      state.activeAtriumBannerId = data;
      console.log(state.activeAtriumBannerId)
    },
    SET_MODAL_SHOW(state, data) {
      state.modalShow = data;
    },
  },

  actions: {
    setEmail({ commit }, email) {
      commit("SET_EMAIL", email);
    },
    setCurrentState({ commit }, currentState) {
      commit("CURRENT_STATE", currentState);
    },
    setSceneLoaded({ commit }, sceneLoaded) {
      commit("SCENE_LOADED", sceneLoaded);
    },
    setInitThreeProgress({ commit }, initThreeProgress) {
      commit("SET_INIT_THREE_PROGRESS", initThreeProgress);
    },
    setMenuFlag({ commit }, menuFlag) {
      commit("MENU_FLAG", menuFlag);
    },
    setCurrentRoom({ commit }, currentRoom) {
      commit("SET_CURRENT_ROOM", currentRoom);
    },
    setAuthUser({ commit }, data) {
      commit("SET_AUTH_USER", data);
    },
    setLoadingSpinner({ commit }, data) {
      commit("SET_LOADING_SPINNER", data);
    },
    setLoadingPercentage({ commit }, data) {
      commit("SET_LOADING_PERCENTAGE", data);
    },
    setAnnotationActiveId({ commit }, data) {
      commit("SET_ANNOTATION_ACTIVE_ID", data);
    },
    setActiveModel({ commit }, data) {
      commit("SET_ACTIVE_MODEL", data);
    },
    setProductContentLoaded({ commit }, data) {
      commit("SET_PRODUCT_CONTENT_LOADED", data);
    },
    setIntroVideoStarted({ commit }, data) {
      commit("SET_INTRO_VIDEO_STARTED", data);
    },
    setProductContentFlagLoaded({ commit }, data) {
      commit("SET_PRODUCT_CONTENT_FLAG_LOADED", data);
    },
    setMenuFlagLoaded({ commit }, data) {
      commit("SET_MENU_FLAG_LOADED", data);
    },
    setAnimationPlay({ commit }, data) {
      commit("SET_ANIMATION_PLAY", data);
    },
    setInfoData({ commit }, data) {
      commit("SET_INFO_DATA", data);
    },
    setMuteVideo({ commit }, data) {
      commit("SET_MUTE_VIDEO", data);
    },
    setMenuClick({ commit }, data) {
      commit("SET_MENU_CLICK", data);
    },
    setAtriumBannerClick({ commit }, data) {
      commit("SET_ATRIUM_BANNER_CLICK", data);
    },
    setModalShow({ commit }, data) {
      commit("SET_MODAL_SHOW", data);
    },
  },
  modules: {},
});
