<template>

    <div class="main-aria" v-if="modalFlag">
      <div class="container-fluid">
        <div class="row justify-content-center " >
          <div
            class="col-md-11 col-lg-9 col-xl-6 item  "
            :class="modal_class"
            data-aos="fade-up"
          >
            <div class="login_box p-5 text-center">
              <div style="position: absolute;top: 7px;right: 30px;"><a href="#" style="font-size: 24px; color: #DF2020;" @click="closePopup()"><i class="far fa-times-circle"></i></a></div>
              <h3>Where would you like to go?</h3>
              <div class="product_btns">
                <h4 class="mt-4">Virtual DR Solutions</h4>
                <ul >
                  <!-- <li><a href="#" @click="loadRoom('DR Room')" :class="isCubeMap === false ? 'disabled' : ''">DR Room </a></li> -->
                  <!-- <li><a href="#"  @click="loadRoom('Gallery Room')" :class="isCubeMap === false ?'disabled' : ''">Mobile DR Room</a></li> -->
                  <li ><a href="#" @click="loadRoom('Gallery Room')" style="padding: 23px 0;">DR Room</a></li>
                  <li><a href="#" @click="loadRoom('DR Room')" >Mobile DR <br/>in patient room</a></li>
                </ul>
                <h4 class="mt-5">Additional Products & Services</h4>
                <ul>
                  <li><a href="#" @click="loadMenu('onDemand')" >SmartXR Assistant</a></li>
                  <li><a href="#" @click="loadMenu('10--onDemand')" >MUSICA Image Processing</a></li>
                  <li><a href="#" @click="loadMenu('8--onDemand')" >Retrofit</a></li>
                  <li><a href="#" @click="loadMenu('9--onDemand')" >Services</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div>
	</section> -->
</template>

<script>
// import axios from 'axios';
export default {
  name: "Products",
  data() {
    return {
      modalFlag: false,
      modal_class: "",
      events: []
    };
  },
  components: {
  },
  mounted() {},
  computed: {
    isCubeMap(){
      return this.$store.state.isCubeMap;
    },
    productDetailsFlag(){
      return this.$store.state.productContentFlagLoaded;
    }
  },
  methods: {
    // login
    showPopup: function(val) {
      if (!val) {
        this.modal_class = "";
        this.modalFlag = val;
        this.$store.dispatch("setMenuFlagLoaded", false);

        return;
      }
      this.modalFlag = val;
      this.$store.dispatch("setMenuFlagLoaded", true);
      var vm = this;
      setTimeout(function() {
        vm.modal_class = "aos-init aos-animate";
      }, 500);
    },
    closePopup(){
        this.modal_class = "";
        this.modalFlag = false;
        this.$store.dispatch("setMenuFlagLoaded", false);
        this.$store.dispatch("setMenuClick", false);

    },
    loadRoom(name) {
      // if(! this.isCubeMap){
      //     alert('button disabled')
      //     return;
      // }
      this.closePopup();
      this.$parent.loadRoom(name);
    },
    loadMenu(menuVal) {
      this.$parent.$refs['Menu'].menuSelected(menuVal);
    },
  },
  watch: {
    productDetailsFlag(val){
      if(val == true){
        this.closePopup()
      }
    }
  }
};
</script>


